body {
  background: white;
  font-family: "Raleway-Regular";
  line-height: 1;
  overflow: hidden; }

.user_top_link {
  color: black !important; }

.three-container {
  position: absolute;
  top: 0;
  z-index: 100; }

.menuwraptop {
  @media screen and (max-width: 767px) {
    margin-bottom: 4vw; }
  @media screen and (min-width: 768px) and (max-width: 979px) {
    margin-bottom: 5.3vw; } }



.wrapColumnMainMenu {
  margin-bottom: 0 !important; }

.containerFullWidthTopText {
  max-width: 100% !important; }

.logoSubText {
  position: relative;
  display: inline-block;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f8f6f4;
  color: #af8400;
  width: 100%;
  text-align: center;
  font-family: "Georgia";
  letter-spacing: 1px; }

@media screen and (min-width: 980px) {
  .container {
    max-width: 850px; } }

@media screen and (min-width: 1180px) {
  .container {
    max-width: 1000px; } }


@media screen and (min-width: 1300px) {
  .container {
    max-width: 1100px; } }

.topline {
 width: 100%;
 background: rgba(70, 70, 70, 0.1);
 text-align: center;
 padding-top: 3px; }

.top_logo_menu {
  width: 160px;
  top: -12px;
  position: relative;
  &::before {
    content: none !important; } }


.newstopitem {
 display: inline-block;
 font-size: 12px;
 line-height: 1;
 color: #464646;
 letter-spacing: -0.29px;
 padding: 6px; }


.brands-list {
  position: absolute;
  top: 76px;
  left: 50%;
  width: 100vw;
  height: 65px;
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  transform-origin: top center;
  transform: translateX(-50%) rotateX(90deg);
  opacity: 0;
  transition: all .3s ease;

  &.open {
    opacity: 1;
    transform: translateX(-50%); }

  &__container {
    max-width: 950px;
    margin: 0 auto;
    height: 100%; }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 25px auto 0;

    &-item {
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-size: 15px;
      color: #a7804e;
      font-weight: bold;
      width: 33.3%;
      text-align: center;
      color: #a2a2a2;
      transition: all 0.3s;
      &:hover {
        color: #a7804e; } } } }


.newstitle {
  font-family: "Raleway-Medium";
  font-size: 9px;
  line-height: 1.4;
  letter-spacing: 2.9px;
  margin-left: 50px;
  color: #717171;
  display: inline-block;
  text-transform: uppercase;
  background: url('../images/news.png');
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 30px;
  background-repeat: no-repeat; }

.headerwrap {
 width: 100%;
 border-bottom: 3px solid gray;
 text-align: center;
 padding-top: 50px;
 padding-bottom: 50px;
 .logo {
  display: inline-block;
  opacity: 0;
  transform: translateY(-45%);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }

.socialwrap {
  bottom: 25px;
  position: absolute; }

.menuwrap {
 width: 100%;
 min-height: 55px;
 margin-top: 0px;
 position: relative;
 z-index: 126;
 padding-top: 10px;
 padding-bottom: 0px;
 transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.is-fixeds {
 position: fixed;
 top: 0px;
 margin-top: 0px;
 padding-top: 30px;
 background: white;
 padding-bottom: 0px;
 z-index: 110; }
.is-hiddens {
 transform: translateY(-100%); }


.menu_items {
  max-width: 950px;
  margin: 0 auto 3px!important;

  .home_menu {
    img {
      position: relative;
      top: 3px; } } }

.wrapslider {
	width: 100%; }

.menuitem {
  display: block;
  font-family: "Raleway-Medium";
  text-transform: uppercase;
  padding: 0 !important;
  position: relative;
  font-size: 12px;
  align-self: center;
  letter-spacing: 2.8px;
  color: #464646;
  &:hover, &:focus, &.activeMenu {
    &::before {
      transform: scale(1, 1); } }
  &::before {
    position: absolute;
    bottom: -20px;
    left: 50%;
    height: 3px;
    width: 50px;
    margin-left: -25px;
    display: block;
    content: '';
    background-color: #000;
    transform: scale(0, 1);
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }

.scrol_right_block {
  display: none; }

.mainbody {
  max-width: 930px;
  padding-right: 1px; }

.title_hover {
  position: relative;
  &:hover {
    .item_title_hover {
      opacity: 1;
      visibility: visible; } }
  .item_title_hover {
    position: absolute;
    top: 180%;
    left: -50%;
    background-color: #fbfbfb;
    border: 1px solid #ece9e9;
    color: #676767;
    font-size: 14px;
    line-height: normal;
    opacity: 0;
    visibility: hidden;
    padding: 2px 5px 3px;
    white-space: nowrap;
    z-index: 100;
    transition: all 0.3s; } }

.flaticon-avatar {
  .item_title_hover {
    left: -30%; } }

.flaticon-magnifying-glass {
  .item_title_hover {
    left: -25%; } }

.flaticon-shopping-cart {
  .item_title_hover {
    left: -45%; } }

.wrapicons {
  display: inline-block;
  .flaticon-shopping-cart, .flaticon-avatar, .flaticon-magnifying-glass {
    &::before {
      font-size: 17px;
      margin-left: 10px; } }
  .icons_menu {
    position: relative;
    padding: 15px 5px 0px 5px;
    cursor: pointer;
    img {
      width: 16px; } }
  .backet_count,
  .icons_menu > .backet_count {
    position: absolute;
    border-radius: 50%;
    background: #d19e66;
    color: white;
    text-align: center;
    font-size: 11px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    top: -7px;
    right: -7px; } }

.mobilemenu {
  width: 100%;
  visibility: hidden; }

.left_top_menu {
  opacity: 0;
  transform: translateX(-15%);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 2; }

.right_top_menu {
  opacity: 0;
  transform: translateX(55%);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background: white;
  z-index: -1; }

.top_menu_ai {
  opacity: 1;
  transform: translate(0, 0); }


.headerwrap {
  .logo_ai {
    opacity: 1;
    transform: translate(0, 0); } }

.lang {
  font-size: 12px;
  padding-top: 13px;
  .lang_item {
    cursor: pointer;
    padding: 5px; }
  .lang_item_active {
    color: #d7ab7a; } }

.lang_r {
  padding-top: 5px; }

.wrapmenu_mob {
  width: 100%;
  padding: 10px;
  text-align: center;
  display: none;
  position: relative; }

.line_krest {
  .ln1 {
    transform: translateY(8px) rotate(45deg); }
  .ln2 {
    display: none; }
  .ln3 {
    transform: translateY(-4px) rotate(-45deg); } }

.main-nav-trigger {
  width: 40px;
  line-height: 12px;
  cursor: pointer;
  position: absolute;
  top: 34%;
  left: 20px;
  z-index: 99;
  span {
    width: 100%;
    height: 3px;
    display: inline-block;
    background: black;
    line-height: 5px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }

.letro_logo_top_mob {
  display: inline-block;
  max-width: 200px;
  width: 100%;
  img {
    display: inline-block;
    width: 141px; } }

@media screen and (max-width: 1300px) {
  .hero-item__content {
    right: inherit !important;
    transform: translate(10px, -50%) !important; } }


@media screen and (min-width: 769px) {
  .left_top_menu {
      width: 90%;
      margin: 0 auto; }
  .menu_items {
      justify-content: space-between;
      display: flex !important; } }


@media screen and (max-width: 769px) {
  .top_logo_menu {
    display: none !important; }

  .wrapmenu_mob {
    display: block; }

  .menuitem {
    font-size: 14px !important;
    width: 100%;
    padding: 15px !important;
    &::before {
      bottom: -1px; } }

  .menu_items {
    display: none;
    background-color: rgba(248,246,244,0.95);
    position: relative;
    height: calc(100vh - 60px);
    overflow: auto;
    width: 100vw;
    left: 0;
    padding-top: 20%;
    transition: visibility 0s linear 0.2s,opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .menuwrap {
    text-align: center;
    margin-top: 0px;
    position: relative;
    z-index: 999;
    padding-top: 0px;
    background: rgba(245, 222, 179, 0);
    .container {
      overflow: hidden;
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }
  .wrapicons {
    float: none; }

  .mosaic-item__content {
    &::before {
      transition-delay: 0s !important;
      transform: scale(1, 1) !important;
      opacity: 0.9 !important; }
    &::after {
      transition-delay: 0.3s !important;
      transform: scale(1, 1) !important; } }

  .label {
    opacity: 1 !important;
    transition-delay: 0.2s !important;
    &::before {
      transform: scale(1, 1) !important; }
    &::after {
      transform: scale(1, 1) !important; } }

  .lang {
    width: 100%; } }

.data-snow {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }


#little-heart1 {
  height: 100px;
  width: 100px;
  position: fixed;
  top: 100%;
  left: 5%;
  transform: rotate(-5deg);
  .heart {
    fill: #fc5b71; } }



#little-heart2 {
  @extend #little-heart1;
  height: 50px;
  width: 50px;
  left: 17%;
  transform: rotate(5deg); }

#little-heart3 {
  @extend #little-heart1;
  height: 75px;
  width: 75px;
  left: 20%;
  transform: rotate(-7deg); }

#little-heart4 {
  @extend #little-heart1;
  height: 20px;
  width: 20px;
  left: 12%;
  transform: rotate(2deg); }

#little-heart5 {
  @extend #little-heart1;
  height: 50px;
  width: 50px;
  left: 10%; }

#little-heart6 {
  @extend #little-heart1;
  height: 30px;
  width: 30px;
  left: 70%;
  transform: rotate(-3deg); }

#little-heart7 {
  @extend #little-heart1;
  height: 20px;
  width: 20px;
  left: 85%;
  transform: rotate(3deg); }

#little-heart8 {
  @extend #little-heart1;
  height: 80px;
  width: 80px;
  left: 75%;
  transform: rotate(2deg); }

#little-heart9 {
  @extend #little-heart1;
  transform: rotate(3deg);
  left: 85%; }

#little-heart10 {
  @extend #little-heart1;
  height: 50px;
  width: 50px;
  left: 95%;
  transform: rotate(5deg); }
