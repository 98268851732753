.wrapMainMiddleCatalog {
  display: none  !important;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0px  !important;
  .loadTopAdaptive {
    margin-bottom: 11px !important;
    .hovering_wrap {
      padding: 0;
      .socialBlockWrap {
        width: 33px;
        .eventProductsLabel {
          font-size: 8px; } }
      .wrap_bottom_prop {
        .cat_scu {
          padding-bottom: 8px;
          font-size: 12px; } }
      .cat_title {
        padding-top: 3px;
        font-size: 12px;
        a {
          padding-bottom: 5px; } } }
    .listItemWrapImg {
      zoom: 40%; } } }


.titleAdaptiveList {
  position: relative;
  width: 100%;
  margin-top: 10px;
  color: #a7804e;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  margin-bottom: 0 !important;
  .titleAdaptiveLeft {
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    span {
      width: 100%;
      height: 1px;
      display: inline-block;
      background-color: #a7804e; } }
  .titleAdaptiveRight {
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center; } }





@media screen and (max-width: 1400px) and (max-height: 850px) {
  .mainCatalogList {
    display: none; }
  .owerWrapDesignList {
    display: none; }
  .wrap_order {
    display: none; }
  .wrapMainMiddleCatalog {
    display: flex  !important; }
  .menuwraptop {
    min-height: 100px !important; }
  .wrap_cat_banner {
    display: none !important; }
  .mainCatalogList {
    display: none !important; }
  .wrapColumnMainMenu {
    .menuitem {
      font-size : 10px; } } }

@media screen and (max-width: 650px) and (max-height: 850px) {
  .wrapTwoAdaptive {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 50% !important;
    display: block;
    flex-basis: auto; } }

@media screen and (max-width: 450px) and (max-height: 850px) {
  .wrapTwoAdaptive {
    width: 100% !important; } }


@media screen and (max-width: 1400px) and (min-height: 850px) {
  .wrapMainMiddleCatalog {
    display: none  !important; }
  .owerWrapDesignList {
    display: block; }
  .mainCatalogList {
    padding-left: 10px;
    padding-right: 10px;
    display: flex !important;
    .bx_catalog_item {
      width: 33.333% !important;
      flex-basis: auto;
      .hovering_wrap {
        margin-bottom: 35px;
        .wrap_bottom_prop {
          top: 1%; } } } } }



@media screen and (max-width: 980px) and (min-height: 850px) {
  .mainCatalogList {
    .bx_catalog_item {
      width: 50% !important; } } }

@media screen and (max-width: 715px) and (min-height: 850px) {
  .mainCatalogList {
    .bx_catalog_item {
      overflow: hidden; } } }

@media screen and (max-width:615px) and (min-height: 850px) {
  .mainCatalogList {
    display: flex !important;
    .bx_catalog_item {
      width: 100% !important;
      overflow: visible; } } }
