.text_name_block {
  blockquote {
    position: relative;
    display: inline-block;
    margin-top: 50%;
    transform: translate(0, -50%);
    width: 70%; } }



.text_name_block {
  blockquote {
    p {
      padding-left: 50px;
      font-family: "freight-big-pro",Georgia,Times,Times New Roman,serif;
      font-weight: 400;
      color: #000;
      font-size: 1.21429rem;
      line-height: 2rem;
      letter-spacing: .26em;
      text-transform: uppercase;
      margin-bottom: .8257rem;
      padding-bottom: .58715rem;
      position: relative;
      padding-top: .69501rem;
      display: inline-block; } } }

.text_name_block {
  blockquote {
    p {
      &::before, &::after {
        opacity: 1;
        transition: opacity 1s 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); } } } }

.text_name_block {
  blockquote {
    p {
      &::before {
        position: absolute;
        top: 8px;
        left: 25px;
        content: '“'; } } } }

.text_name_block {
  blockquote {
    p {
      &::after {
        margin-left: 15px;
        content: '”'; } } } }

.text_name_block {
  blockquote {
    cite {
      opacity: 1;
      transform: translate(0, 0);
      transition: opacity 2s 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 2s 0.6s cubic-bezier(0.19, 1, 0.22, 1);
      font-family: "freight-big-pro",Georgia,Times,Times New Roman,serif;
      font-weight: 700;
      font-size: .64286rem;
      line-height: 2rem;
      margin-bottom: .21856rem;
      padding-top: .78144rem;
      display: block;
      color: #a68676;
      letter-spacing: .6em;
      text-transform: uppercase;
      font-style: normal; } } }

.text_name_block {
  blockquote {
    cite {
      &::before {
        transition: width 2s 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        width: 25px;
        margin-right: 18px;
        margin-bottom: 2px;
        margin-left: 7px;
        height: 1px;
        width: 25px;
        display: inline-block;
        content: '';
        background-color: #cfbda8; } } } }


.text_name_block2 {
  padding-top: 240px; }

.text_name_block2 {
  &::before {
    position: absolute;
    top: 28px;
    left: 33%;
    height: 218px;
    width: 1px;
    display: block;
    content: '';
    background-color: #e3ded9;
    z-index: 1; } }

.text_name_block2 {
  .label {
    position: absolute;
    top: 28px;
    left: 33%;
    font-family: "freight-big-pro",Georgia,Times,Times New Roman,serif;
    font-weight: 500;
    font-style: italic;
    font-size: 1.28571rem;
    color: #afa59c;
    background-color: white;
    transform-origin: right center;
    transform: translate(-100%, -50%) rotate(-90deg);
    z-index: 2;
    &::before {
      margin-bottom: 2px;
      height: 7px;
      width: 7px;
      display: inline-block;
      content: '';
      border: 1px solid #e3ded9;
      border-radius: 100%;
      margin-right: 18px; }
    &::after {
      margin-bottom: 2px;
      height: 7px;
      width: 7px;
      display: inline-block;
      content: '';
      border: 1px solid #e3ded9;
      border-radius: 100%;
      margin-left: 18px; } } }

.text_name_block2 {
 img {
  opacity: 1;
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-top: 15px; } }

.titlevideo-link {
 padding-right: 40px;
 font-family: "raleway",Helvetica Neue,Helvetica,Arial,sans-serif;
 font-weight: 700;
 font-style: normal;
 position: relative;
 display: inline-block;
 font-size: .85714rem;
 line-height: 2.85714rem;
 letter-spacing: .2em;
 text-transform: uppercase;
 color: #000;
 z-index: 1;
 &::before {
  position: absolute;
  right: 0;
  bottom: -24px;
  height: 3px;
  width: 100%;
  display: block;
  content: '';
  background-color: #000;
  transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),left 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),right 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),left 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),right 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),left 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),right 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
 &::after {
  position: absolute;
  top: 47%;
  right: -50px;
  height: 1px;
  width: 50px;
  display: block;
  content: '';
  background-color: #e3ded9;
  z-index: -1;
  transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
 &:hover, &:focus {
  &::before {
   right: 44px;
   width: calc(100% - 44px);
   transition-delay: 0s, 0s, 0s, 0.1s; } }
 &:hover, &:focus {
  &::after {
   width: calc(100% - 50px);
   transform: translate(-100px, 0);
   transition-delay: 0s, 0.1s; } } }

.video_block {
 margin-top: 84px;
 video {
  margin-bottom: -2px; } }

.comment-right {
 margin-left: 80px; }


.blast-root {
  display: none; }

.scrol_right_block {
  position: fixed;
  top: 250px;
  left: calc((100vw - 930px) / 2 + 930px);
  font-family: "raleway",Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 500;
  font-style: normal;
  width: 380px;
  font-size: 12px;
  line-height: 1.35714rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  //color: #afa59c
  color: #cf0007;
  transform: rotate(90deg);
  z-index: 300;
  strong {
    color: #a57b52; } }


.hover_img_filter {
  opacity: 0.3;
  filter: grayscale(100%);
  transition: all 0.2s;
  width: 80%;
  &:hover {
    transform: scale3d(1.1,1.1,1.1);
    filter: grayscale(100%);
    filter: grayscale(0);
    filter: none;
    opacity: 1; } }

.reklam_bottom_block {
  blockquote {
    margin-top: 0 !important;
    transform: translate(0) !important; } }

@media screen and (max-width: 769px) {
  .slider_title_block {
    padding-left: 5px;
    padding-right: 5px; }
  .text_name_block {
    blockquote {
      p {
        &::before {
          left: 0px; }
        padding-left: 10px; } } }
  .reklam_bottom_block {
    padding: 0px !important;
    .rek_borders {
      border: none !important;
      padding: 0px !important; } }



  .comment-right {
    margin-left: 0px; }
  .text_name_block {
    blockquote {
      display: inline-block;
      position: relative;
      top: 0;
      margin-top: 0;
      transform: translate(0, 0);
      width: 100%;
      padding: 10px; } }
  .text_name_block2 {
    .label, &::before {
      left: 50%; } } }


.reklam_bottom_block {
  padding-top: 65px;
  padding-bottom: 10px;
  margin-top: 15px;
  margin-bottom: 65px;
  a {
    img {
      margin-top: 30px; } }
  .rek_borders {
    border-left: 1px solid #cacacd;
    border-right: 1px solid #cacacd; }
  .text_name_block {
    blockquote {
      text-align: left;
      width: 85%; } } }





.scroll_go {
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate(0, 200px); }

.scrolling {
  transform: translate(0, 0); }

.text_vertikal {
  font-family: "freight-big-pro",Georgia,Times,Times New Roman,serif;
  font-weight: 500;
  font-style: italic;
  color: #afa59c;
  font-size: 1.28571rem;
  a {
    color: #afa59c; } }


.slick-slider_bottom {
  .captions_transform_wrap {
    margin-left: 45%;
    margin-top: 10px; } }

.captions_transform_wrap {
  float: left;
  &::after {
    margin-top: -3px;
    margin-left: 7px;
    height: 98px;
    width: 1px;
    display: block;
    content: '';
    background-color: #e3ded9; }
  .captions_transform {
    transform: rotate(180deg);
    div {
      display: inline-block;
      float: left;
      transform: rotate(90deg); }
    span {
      display: inline-block; }
    &::before {
      margin-bottom: 3px;
      height: 7px;
      width: 7px;
      display: inline-block;
      content: '';
      border: 1px solid #e3ded9;
      border-radius: 100%;
      margin-left: 4px; }
    &::after {
      margin-bottom: 1px;
      height: 7px;
      width: 7px;
      display: inline-block;
      content: '';
      border: 1px solid #e3ded9;
      border-radius: 100%;
      margin-left: 4px; } } }
