@import "../node_modules/bulma/bulma";

@import "./partials/fonts";

@import "./partials/mixins";

@import "./partials/colors";

@import "./partials/header";

@import "./partials/main";

@import "./partials/slider";

@import "./partials/order";

@import "./partials/user";

@import "./partials/body_img";

@import "./partials/body_text";

@import "./partials/catalog";

@import "./partials/catalogMiddle";

@import "./partials/elementAdaptive";

@import "./partials/cart";

@import "./partials/page";

@import "./partials/contacts";

@import "./partials/brend";

@import "./partials/milagroItemPage";

@import "./partials/about";

@import "./partials/action";

@import "./partials/footer";
