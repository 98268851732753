.salewrap {
 padding-top: 50px;
 padding-bottom: 50px;
 background: #afb3b9;
 border-bottom: 1px solid #84878e;
 .text_bottom_form {
  color: white;
  text-transform: uppercase;
  font-size: 32px;
  span {
   font-family: "GothamProBold"; } } }

.input_bottom_form {
 padding: 20px;
 background: white;
 width: 100%;
 font-size: 20px;
 outline: none; }

.submit_bottom_form {
 padding: 21px;
 background: white;
 width: 100%;
 font-size: 23px;
 text-transform: uppercase;

 color: #585a67;
 display: inline-block;
 text-align: center;
 cursor: pointer; }

.footer_block {
 padding-top: 40px;
 padding-bottom: 40px;
 max-width: 930px;
 .columns {
   margin: 0px; }
 .column {
   padding: 0px; } }

.bottom_menu {
 width: 100%;
 text-transform: uppercase;
 .botom_menu_item {
  width: 100%;
  margin-bottom: 15px;
  a {
   color: #585a67; } } }

.bottom_menu_right {
 min-width: 451px; }

.item_menu_right {
 text-transform: uppercase;
 color: #585a67;
 padding: 7px 0px 7px 0px;
 display: inline-block; }

.bottom_socials {
  width: 140px;
  display: inline-block;
  margin: 9px 0 0;
  .item_socials {
    text-align: center;
    margin-top: 10px; }
  .flaticon-twitter-logo-silhouette, .flaticon-vk-social-logotype, .flaticon-instagram, .flaticon-facebook-letter-logo {
    &::before {
      font-size: 13px;
      color: #9b9b9b;
      margin-left: 0px;
      position: relative;
      top: 2px; }
    &:hover {
      &::before {
        font-size: 15px; } } }
  .flaticon-vk-social-logotype {
    &::before {
      font-size: 16px; }
    &:hover {
      &::before {
        font-size: 18px; } } } }





.convert_bottom {
 background: #e7e7e7;
 .convert_img_bottom {
  margin-top: 6px; } }

.caption_bottom {
 a {
  color: #9b9b9b;
  font-size: 11px; } }

.qwestions {
  padding: 6px 6px 6px 9px;
  font-size: 11px;
  font-family: "Raleway-Regular";
  text-align: left;
  width: 320px;
  background-color: #d8d8d8;
  color: white;
  background-image: url('../images/bottom_conv_07.png');
  background-position: 98% 4px;
  background-repeat: no-repeat;
  cursor: pointer;
  letter-spacing: 0.3em; }

.bottom_500 {
  background-image: url('../images/bottom_500.png');
  //background-image: url('../images/footbaner.jpg')
  min-height: 351px;
  margin-bottom: 20px;
  position: relative;
  .poluch {
    position: absolute;
    left: 170px;
    top: 193px;
    font-family: 'Georgia';
    font-size: 14px;
    letter-spacing: 3px;
    color: #998b84;
    &::after {
      position: absolute;
      top: 144.7%;
      right: 100%;
      height: 1px;
      width: calc((100vw - 1280px) / 2 + 170px);
      display: block;
      content: '';
      background: #cfbda8;
      transform: translate(0, -15px);
      transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
    &::before {
      margin-bottom: 2px;
      margin-right: 5px;
      height: 7px;
      width: 7px;
      display: inline-block;
      content: '';
      border: 1px solid #cfbda8;
      border-radius: 100%; } }
  .bit_500_text {
    margin-left: 170px;
    margin-top: 224px;
    max-width: 350px;
    font-family: 'Georgia';
    line-height: 1.4;
    font-size: 32px;
    letter-spacing: 8px;
    color: #000000;
    text-transform: uppercase; }
  .mail_500_input {
    outline: none;
    border: none;
    font-family: 'Raleway-Regular';
    font-size: 14px;
    letter-spacing: 3px;
    color: #998b84;
    text-align: center;
    background: none;
    padding: 2px;
    width: 80%;
    float: left;
    margin-left: 40px; } }

.menu_foter {
  margin-top: 12px !important;

  display: inline-block;
  text-align: justify;
  text-align-last: justify;
  width: 100%;
  &::after {
    width: 100%;
    height: 0px;
    visibility: hidden;
    overflow: hidden;
    content: '';
    display: inline-block; }
  .item_menu_foter {
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    &:last-child {}
    //  padding-right: 60px
 }    //  border-right: 1px solid #998b84
  a {
    font-size: 11px;
    font-family: 'Raleway-Medium';
    text-transform: uppercase;
    color: #9b9b9b;
    letter-spacing: 2px; } }




.bottom_line {
  margin-top: 10px !important;
  padding-top: 40px !important;
  border-top: 1px solid #cacacd; }

.submit_go {
  width: 26px;
  height: 17px;
  font-family: "Raleway-Regular";
  cursor: pointer;
  font-size: 14px;
  float: right;
  display: none; }

.wrap_500_mail {
  position: relative;
  border-bottom: 1px solid #998b84; }

.wrap_500_mail_hover {
  padding: 10px;
  border-radius: 10pc;
  background-color: green;
  margin-top: 45%;
  left: 2%;
  width: 80%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.0);
  transition: all 0.5s; }



.slick-slide {
  outline: none; }

.menu_foter {
  a {
    &:hover {
      color: #000;
      text-decoration: none;
      outline: none; } } }

@media screen and (max-width: 769px) {
  .menu_foter {
    text-align: center;
    text-align-last: center;
    .item_menu_foter {
      width: 100%;
      padding: 5px; } }

  .bottom_menu_right {
    width: 100%; }
  .wrap_500_mail {
    width: 100%; }

  .menu_foter {
    .column {
      float: left;
      margin-left: 10px;
      margin-top: 4px; } }

  .item_menu_foter {
    &:last-child {
      padding-right: 5px  !important;
      border: none  !important; } }

  .bottom_socials {
    width: 100%;
    padding-top: 20px;
    text-align: justify;
    text-align-last: justify;
    &::after {
      width: 100%;
      height: 0px;
      visibility: hidden;
      overflow: hidden;
      content: '';
      display: inline-block; }
    .item_socials {
      display: inline-block;
      span {
        &::before {
          font-size: 30px; }
        &:hover {
          &::before {
            font-size: 30px; } } } } }

  .qwestions_wrap {
    width: 100%;
    text-align: center;
    .qwestions {
      float: none;
      display: inline-block; } }

  .caption_bottom {
    width: 100%;
    text-align: center; }

  .bottom_500 {
    text-align: center;
    .poluch {
      top: 133px; }
    .bit_500_text {
      margin-left: 0px;
      max-width: 100%;
      margin-top: 180px; } }

  .wrap_500_mail_hover {
    width: 100%;
    left: 0;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.63); }

  .wrap_bottom_img {
    width: 100%;
    text-align: center;
    img {
      float: none; } } }
