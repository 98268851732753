.brandWrapText {
  width: 100%;
  position: relative;
  margin-top: 20px; }

.brendsImgTopWrap {
  text-align: center; }


.brendTabStyle {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(100%);
  transition: all 0.3s;
  &:hover {
    filter: grayscale(0); }
  &.atciveTabGo {
    filter: grayscale(0); } }

.brendTabMiddle {
  width: 100%;
  margin-top: 25px;
  position: relative;
  display: none; }

.brendMiddleTitle {
  font-size: 15px;
  color: #464646;
  font-family: "Raleway-Medium";
  text-align: right;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  &::after {
    position: absolute;
    top: 141%;
    left: calc(101%);
    height: 1px;
    width: calc((100vw - 1280px) / 2 + 720px);
    display: block;
    content: '';
    background: #000000;
    transform: translate(0, -15px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } }

.brendMiddleText {
  font-size: 11px;
  color: #464646;
  font-family: "Raleway-Medium";
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 20px;
  line-height: 1.5;
  padding: 10px; }

.brendTabMiddle {
  .text_name_block {
    font-weight: bold;
    text-align: left;
    blockquote {
      text-align: left;
      margin-top: 3% !important;
      transform: translate(0, 0)  !important;
      p {
        font-size: 14px !important;
        font-weight: bold !important;
        font-family: "Georgia" !important; }
      cite {
        font-size: 9px !important;
        font-weight: bold !important;
        font-family: "Georgia" !important;
        letter-spacing: 1px  !important; } } } }

.brendTabBottom {
  position: relative;
  width: 100%;
  display: none;
  .items-slider {
    background: none !important;
    &::after {
      background: none !important; }
    &::before {
      background: none !important; } } }

.atciveSliderBrendsGo {
  display: block; }

.atciveTabMiddle {
  display: flex !important; }


.brendTabStyleLeft {
  justify-content: flex-start; }

.brendTabStyleRight {
  justify-content: flex-end; }

.wrapBrendLogo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f6f4;
  padding-top: 55px;
  padding-bottom: 55px;
  cursor: pointer;
  img {
    display: inline-block;
    opacity: 0.7;
    &:hover {
      opacity: 1; } } }


.lineItemBrend {
  margin: 0 !important;
  padding: 0 !important;
  display: none;
  .lineItemBrendRight {
    padding-top: 55px;
    padding-bottom: 55px; }
  .lineItemBrendLeft {
    padding: 5px;

    &::before {
      content: none;
      background-color: #f7f6f4;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: -2000px;
      top: 0; } } }


//@media screen and (max-width: 1000px)
.lineItemBrend {
  .lineItemBrendRight {
    padding-top: 10px; }
  .brendMiddleTitle {}
  .lineItemBrendLeft {
    max-width: 100%;
    padding-right: 0; } }

.itemBrendInfo {
  background-color: #f8f6f4;
  padding-top: 65px;
  padding-bottom: 65px;
  margin: 0;
  margin-top: 20px;
  width: 100%;
  padding-right: 70px;
  position: relative;
  display: none;
  .itemBrendInfoLeft {
    justify-content: center;
    align-items: center;
    display: flex; } }

.textInfoBrend {
  .textInfoBrendTitle {
    width: 100%;
    color: #ad8667;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: inline-block; }
  .textInfoBrendMain {
    display: inline-block;
    padding-top: 25px;
    width: 100%;
    line-height: 1.4;
    letter-spacing: 1px;
    color: #686868; } }


.closeBrendInfo {
  font-size: 12px;
  color: #ad8667;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 70px;
  cursor: pointer;
  &:hover {
    color: #b5733f; } }

.brendTopShow {
  display: flex; }

@media screen and (max-width: 1400px) and (max-height: 850px) {
  .brendTabMiddle {
    zoom: 80%; }
  .brendsImgTopWrap {
    img {
      max-height: 220px; } } }


@media screen and (max-width: 768px) {
  .brendTabMiddle {
    zoom: 100%; }
  .brendsImgTopWrap {
    img {
      max-height: inherit; } }
  .brendTopShow {
    display: block; }
  .itemBrendInfo {
    padding-right: 10px; }
  .closeBrendInfo {
    right: 20px; } }
