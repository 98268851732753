@font-face {
  font-family: "SegoeUIRegular";
  src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot");
  src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/SegoeUIRegular/SegoeUIRegular.woff2") format("woff2"),
  url("../fonts/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "SegoeUIBold";
  src: url("../fonts/SegoeUIBold/SegoeUIBold.eot");
  src: url("../fonts/SegoeUIBold/SegoeUIBold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/SegoeUIBold/SegoeUIBold.woff2") format("woff2"),
  url("../fonts/SegoeUIBold/SegoeUIBold.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "SegoeUIItalic";
  src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot");
  src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/SegoeUIItalic/SegoeUIItalic.woff2") format("woff2"),
  url("../fonts/SegoeUIItalic/SegoeUIItalic.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "SegoeUILight";
  src: url("../fonts/SegoeUILight/SegoeUILight.eot");
  src: url("../fonts/SegoeUILight/SegoeUILight.eot?#iefix")format("embedded-opentype"),
  url("../fonts/SegoeUILight/SegoeUILight.woff2") format("woff2"),
  url("../fonts/SegoeUILight/SegoeUILight.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "SegoeUISemiBold";
  src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot");
  src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/SegoeUISemiBold/SegoeUISemiBold.woff2") format("woff2"),
  url("../fonts/SegoeUISemiBold/SegoeUISemiBold.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Regular";
  src: url("../fonts/relaway/Raleway-Regular.eot");
  src: url("../fonts/relaway/Raleway-Regular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/relaway/Raleway-Regular.woff") format("woff"),
  url("../fonts/relaway/Raleway-Regular.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Regular-italic";
  src: url("../fonts/relaway/Raleway-Regular-italic.eot");
  src: url("../fonts/relaway/Raleway-Regular-italic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/relaway/Raleway-Regular-italic.woff") format("woff"),
  url("../fonts/relaway/Raleway-Regular-italic.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }


@font-face {
  font-family: "Raleway-Bold";
  src: url("../fonts/relaway/Raleway-Bold.eot");
  src: url("../fonts/relaway/Raleway-Bold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/relaway/Raleway-Bold.woff") format("woff"),
  url("../fonts/relaway/Raleway-Bold.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }


@font-face {
  font-family: "Raleway-Medium";
  src: url("../fonts/relaway/Raleway-Medium.eot");
  src: url("../fonts/relaway/Raleway-Medium.eot?#iefix")format("embedded-opentype"),
  url("../fonts/relaway/Raleway-Medium.woff") format("woff"),
  url("../fonts/relaway/Raleway-Medium.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }


@font-face {
  font-family: "Raleway-Light";
  src: url("../fonts/relaway/Raleway-Light.eot");
  src: url("../fonts/relaway/Raleway-Light.eot?#iefix")format("embedded-opentype"),
  url("../fonts/relaway/Raleway-Light.woff") format("woff"),
  url("../fonts/relaway/Raleway-Light.ttf") format("truetype") {}
  font-style: normal;
  font-weight: normal; }
