.elemActionLine {
  margin-bottom: 0 !important;
  margin-top: 85px; }


.elemActionLineLeft {
  position: relative;
  background-color: #f7f6f4;
  padding: 0 !important;
  padding-left: 190px !important;
  &::before {
    content: ' ';
    background-color: #f7f6f4;
    position: absolute;
    width: 2000px;
    height: 100%;
    left: -2000px;
    top: 0; } }

.wrapActionLink {
  position: relative;
  width: 100%;
  margin-top: 75px;
  width: 100%;
  .actionLink {
    font-family: "Raleway-Regular";
    position: relative;
    display: inline-block;
    color: #a7804e;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    &:hover {
      color: rgba(167, 128, 78, 0.61); }
    .flaticon-arrows-1 {
      position: relative;
      &::before {
        margin-left: 0 !important;
        font-size: 11px;
        margin-right: 8px; } } } }


.wrapTitleAction {
  position: relative;
  width: 100%;
  margin-top: 65px;
  .titleAction {
    font-family: "Raleway-Regular";
    position: relative;
    display: inline-block;
    color: #2f2f2f;
    text-transform: uppercase;
    font-size: 30px;
    letter-spacing: 3px;
    max-width: 370px;
    line-height: 1.3;
    font-weight: bold; } }

.wrapDateAction {
  position: relative;
  width: 100%;
  margin-top: 55px;
  .dateAction {
    font-family: "Georgia";
    position: relative;
    display: inline-block;
    color: #a7804e;
    font-size: 13px;
    &::before {
      content: ' ';
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #dbcab1;
      top: 3px;
      left: -15px; }
    &::after {
      content: ' ';
      position: absolute;
      height: 1px;
      width: 1350px;
      left: -1364px;
      top: 5.5px;
      background-color: #dbcab1; } } }

.elemActionLineRight {
  padding: 0 !important;
  img {
    display: inline-block;
    max-width: 100%; } }

.wrapTextAction {
  position: relative;
  width: 100%;
  margin-top: 25px;
  .textAction {
    font-family: "Raleway-Regular";
    position: relative;
    display: inline-block;
    color: #b4b4b4;
    font-size: 16px;
    max-width: 345px;
    margin-bottom: 50px;
    line-height: 1.8;
    letter-spacing: -1px;
    .milagroDopTextInfoBlock {
      padding: 0; } } }

.elemActionLineRightText {
  .elemActionLineRight {
    text-align: right; }
  .elemActionLineLeft {
    padding-left: 130px !important;
    &::before {
      content: none; }
    &::after {
      content: ' ';
      background-color: #f7f6f4;
      position: absolute;
      width: 2000px;
      height: 100%;
      right: -2000px;
      top: 0; }
    .wrapDateAction {
      z-index: 1;
      .dateAction {
        &::before {
          content: ' ';
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          border: 1px solid #dbcab1;
          top: 3px;
          left: 125px; }
        &::after {
          content: ' ';
          position: absolute;
          height: 1px;
          width: 1350px;
          left: 131px;
          top: 5.5px;
          background-color: #dbcab1; } } } } }

@media screen and (max-width: 1000px) {
  .wrapActionLink {
    margin-top: 25px; }
  .wrapTextAction {
    .textAction {
      padding-right: 15px; } }
  .elemActionLine {
    .elemActionLineLeft {
      padding-left: 20px !important;
      padding-top: 1px !important; } } }
