.wrapContactBlock {
  position: relative;
  height: 796px;
  img {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .wrapContactInfo {
    position: relative;
    padding-top: 97px;
    padding-right: 53px;
    padding-left: 830px;
    padding-bottom: 90px;
    left: -700px;
    background-color: #f8f6f4;
    top: 38%; } }


.tittleContact {
  position: relative;
  text-transform: uppercase;
  color: #2f2f2f;
  font-size: 14px;
  letter-spacing: 4px;
  font-family: "Georgia";
  font-style: normal;
  font-weight: bold;
  margin-left: 20px;
  &::after {
    height: 7px;
    width: 7px;
    display: inline-block;
    content: '';
    border: 1px solid #b5998c;
    border-radius: 100%;
    position: absolute;
    left: -30px;
    top: 3px; }
  &::before {
    width: 800px;
    height: 1px;
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: -830px;
    background: #b5998c;
    transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } }
.textContacts {
  margin-top: 20px;
  position: relative;
  font-size: 10px;
  font-family: "Georgia";
  color: #444343;
  font-style: italic;
  margin-left: 20px; }


.wrapBottomTextContacts {
  position: relative;
  width: 100%;
  margin-top: 20px;
  color: #b5998c;
  font-size: 12px;
  span {
    margin-right: 10px;
    display: inline-block; }
  a {
    display: inline-block;
    text-decoration: none;
    &:hover {
      color: #b5998c; } } }


@media screen and (max-width: 1295px) {
  .wrapContactInfo {
    width: 600px;
    padding-left: 100px !important;
    left: inherit !important; } }


@media screen and (max-width: 1000px) {
  .wrapContactInfo {
    padding-left: 35px !important; } }

@media screen and (max-width: 1180px) {
  .wrapContactBlock {
    max-height: 796px !important;
    height: inherit !important;
    overflow: hidden; } }
@media screen and (max-width: 850px) {
  .wrapContactInfo {
    top: 25% !important; } }
@media screen and (max-width: 770px) {
  .wrapContactInfo {
    width: 100%; } }
