.wrap_list_slider {
  width: 100px; }


.petals {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    display: none; }

  .petal {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    position: absolute;
    top: -10rem;
    bottom: 0;
    z-index: 150; }

  .petal .rotate {
    animation: driftyRotate 1s infinite both ease-in-out; }

  .petal .askew {
    background: currentColor;
    transform: skewY(10deg);
    display: block;
    width: 2rem;
    height: 2rem;
    animation: drifty 1s infinite alternate both ease-in-out; }


  .petal {
    color: rgba(0,0,0,0); }

  .petal:nth-of-type(7n) .askew {
    animation-delay: -.6s;
    animation-duration: 2.25s; }

  .petal:nth-of-type(7n + 1) .askew {
    animation-delay: -.879s;
    animation-duration: 3.5s; }

  .petal:nth-of-type(7n + 2) .askew {
    animation-delay: -.11s;
    animation-duration: 1.95s; }

  .petal:nth-of-type(7n + 3) .askew {
    animation-delay: -.246s;
    animation-duration: .85s; }

  .petal:nth-of-type(7n + 4) .askew {
    animation-delay: -.43s;
    animation-duration: 2.5s; }

  .petal:nth-of-type(7n + 5) .askew {
    animation-delay: -.56s;
    animation-duration: 1.75s; }

  .petal:nth-of-type(7n + 6) .askew {
    animation-delay: -.76s;
    animation-duration: 1.5s; }


  .petal:nth-of-type(9n) .rotate {
    animation-duration: 2s; }

  .petal:nth-of-type(9n + 1) .rotate {
    animation-duration: 2.3s; }

  .petal:nth-of-type(9n + 2) .rotate {
    animation-duration: 1.1s; }

  .petal:nth-of-type(9n + 3) .rotate {
    animation-duration: .75s; }

  .petal:nth-of-type(9n + 4) .rotate {
    animation-duration: 4.3s; }

  .petal:nth-of-type(9n + 5) .rotate {
    animation-duration: 3.05s; }

  .petal:nth-of-type(9n + 6) .rotate {
    animation-duration: 2.76s; }

  .petal:nth-of-type(9n + 7) .rotate {
    animation-duration: 7.6s; }

  .petal:nth-of-type(9n + 8) .rotate {
    animation-duration: 1.78s; } }


@keyframes drifty {
  0% {
    transform: skewY(10deg) translate3d(-250%, 0, 0);
    display: block; }
  100% {
    transform: skewY(-12deg) translate3d(250%, 0, 0);
    display: block; } }


@keyframes driftyRotate {
  0% {
    transform: rotateX(0);
    display: block; }
  100% {
    transform: rotateX(359deg);
    display: block; } }



.go_big_img_active {
  border: 1px solid rgba(226, 163, 83, 0.81) !important; }

.go_big_img {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 7px !important;
  display: inline-block !important;
  text-align: center;
  &:hover {
    border: 1px solid rgba(226, 163, 83, 0.81) !important; }
  img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block !important;
    &:hover {}
 } }    //  filter: drop-shadow(2px 2px 2px rgba(210, 155, 85, 0.65))



.slick-slider {
 margin-bottom: 0px !important; }

.slider {
  position: relative; }
.wrap_bottom_slider_img {
  overflow: hidden;
  width: 100%;
  padding: 0 !important;
  text-align: center;
  img {
    display: inline-block; } }

.main-content {
  .wrapslider {
    .wrap_item_slider {
      img {
        width: 100%; } } } }

@media screen and (max-width: 87.49em) {
  .slider_dots_wrap {
   height: 65px;
   width: 100%;
   left: 0;
   transform: none;
   z-index: 1;
   background-color: #f8f6f4;
   margin-bottom: 20px; }

  .wrapslider {
    overflow: hidden; } }



@media screen and (min-width: 87.49em) {
  .slider_dots_wrap {
   position: absolute;
   height: calc((100vw - 1200px) / 2);
   width: 70%;
   left: 0px;
   bottom: 72px;
   z-index: 10;
   background-color: #f8f6f4;
   transform-origin: left bottom;
   transform: rotate(-90deg); }
  .wrapslider {
   margin-bottom: 50px; } }

.slider_dots_wrap {
 &::before {
  position: absolute;
  right: 160px;
  bottom: 30px;
  left: 50px;
  height: 1px;
  width: auto;
  display: block;
  content: '';
  background-color: #cfbda8; } }

.slick-dots2 {
 position: absolute;
 padding-right: 14px;
 padding-left: 14px;
 height: 25px;
 background-color: #f8f6f4;
 z-index: 1;
 bottom: 20px;
 left: 50%;
 li {
  margin: 0 4px;
  padding: 0;
  height: 25px;
  width: 25px;
  position: relative;
  display: block;
  float: left;
  cursor: pointer;
  background-color: transparent;
  background-image: none;
  border: 0 none;
  border-radius: 100%;
  &::before {
   position: absolute;
   top: 9px;
   left: 9px;
   height: 7px;
   width: 7px;
   display: block;
   content: '';
   border: 1px solid #cfbda8;
   border-radius: 100%;
   transition: width 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  &::after {
   position: absolute;
   top: 10px;
   left: 10px;
   height: 5px;
   width: 5px;
   display: block;
   content: '';
   border-radius: 100%;
   z-index: 2;
   transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }
 button {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; } }

.slick-dots2 {
  .slick-active {
   &::before {
    height: 25px !important;
    width: 25px !important;
    transform: translate(-9px, -9px); }
   &::after {
    background: black; } } }

.wrap_item_slider {
  position: relative;
  //&::before
  ////position: absolute
  ////top: 55.8%
  ////left: -webkit-calc(100% - 152px)
  ////left: calc(100% - 152px)
  ////height: 1px
  ////width: -webkit-calc((100vw - 1280px) / 2 + 152px)
  ////width: calc((100vw - 1280px) / 2 + 152px)
  //  display: block
  //  content: ''
  //  background: #cfbda8
  //  transform: translate(0, -15px)
 }  //  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94),width 0.6s cubic-bezier(0.19, 1, 0.22, 1)


@media screen and (min-width: 46.25em) {
  .hero-item__title {
    color: #f8f6f4 !important; } }


.hero-item__content {
  outline: none;
  position: absolute;
  top: 55.8%;
  right: 87px;
  opacity: 0;
  transform: translate(-60px, -50%);
  z-index: 50;
  transition: opacity 2.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 2.8s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 2.8s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  .hero-item__title {
    font-family: "freight-big-pro",Georgia,Times,Times New Roman,serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    letter-spacing: .48em;
    text-transform: uppercase;
    color: #a68676;
    margin-bottom: 10px;
    &::before, &::after {
      margin-bottom: 2px;
      margin-right: 5px;
      height: 7px;
      width: 7px;
      display: inline-block;
      content: '';
      border: 1px solid #cfbda8;
      border-radius: 100%; } }
  a {
    outline: none;
    padding-left: 40px;
    margin-top: 10px;
    font-family: "raleway",Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: 700;
    font-style: normal;
    position: relative;
    font-size: .78571rem;
    letter-spacing: .48em;
    text-transform: uppercase;
    color: white;
    &:hover {
      &::before {
        opacity: 1;
        width: -webkit-calc(100% - 70px);
        width: calc(100% - 70px);
        transform: translate(40px, 0); } }
    &::before {
      position: absolute;
      top: 6px;
      left: 0;
      height: 1px;
      width: 25px;
      display: inline-block;
      content: '';
      background: #e3ded9;
      z-index: -1;
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    &::after {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 2px;
      width: 12px;
      display: block;
      content: '';
      background-color: #000;
      opacity: 0;
      transform: translate(-10px, 0);
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),-webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    i {
      margin-bottom: 1px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      &::before {
        content: ">";
        top: -2px;
        position: relative; } } } }

.slick-active {
  .hero-item__content {
    outline: none;
    opacity: 1; } }

.title_sliders {
  position: relative;
  width: 250px;
  font-family: "Raleway-Bold";
  display: none;
  &:hover {
    &::before {
      transform: translate(-235px, -15px);
      transition-delay: 0s, 0.1s; }
    &::after {
      width: calc(100% - 50px);
      transition-delay: 0s, 0.1s; } }
  span {
    font-size: .85714rem;
    line-height: 2.85714rem;
    letter-spacing: .2em;
    text-transform: uppercase; }
  &::after {
    position: absolute;
    left: 0;
    bottom: -24px;
    height: 3px;
    width: 100%;
    display: block;
    content: '';
    background-color: #000;
    transition: width 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), left 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), right 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  &::before {
    position: absolute;
    top: 83%;
    left: calc(100% - 22px);
    height: 1px;
    width: calc((100vw - 1280px) / 2 + 930px);
    display: block;
    content: '';
    background: #cfbda8;
    transform: translate(0, -15px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
  a {
    color: black; }
  .instimgbottom {
    margin-left: 30px; } }

.slider_title_block {
  margin-top: 40px;
  max-width: 930px; }

.slick-slider_bottom {
 }  //margin-left: 35%

.tnb2_ins_sl {
  position: relative;
  padding-top: 240px !important; }

.wrapslider_bottom {
  padding-bottom: 30px;
  position: relative;
  .instimgbottom {
    position: absolute;
    top: -30px;
    left: calc(50% - 67px); }
  div {
    padding: 15px 2px 0px 0px; }

  .captions_transform_wrap {
    padding: 0px 15px 0px 0px;
    div {
      padding: 0px 15px 0px 0px; } } }


.wrapslider_bottom {
  .slick-prev {
    top: 34%;
    &::before {}
    //  color: #afa59c
    //  content: " "
    //  background-image: url("../images/arr_left.png")
    //  width: 13px
    //  height: 19px
 }    //  display: inline-block
  .slick-slide {
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale3d(1.3, 1.3, 1.3); } } } }

.wrapslider {
  .slick-list {
    overflow: visible; } }

.slider_bottom {
  .slick-prev {
    transition: all 0.3s;
    &:hover {
      transform: translate(-25%, -50%) scale(1.2); } } }


.marquee_wrap {
  marquee {
    text-align: justify;
    text-align-last: justify; } }


@media screen and (max-width: 1400px) and (max-height: 850px) {
  .wrap_item_slider {
    overflow: hidden; } }

@media screen and (max-width: 769px) {
  .slick-slider_bottom {
    margin-left: 0%; }

  .title_sliders {
    &::before {
      content: none; } }

  .scrol_right_block {
    display: none; } }
