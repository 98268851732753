// indents
$props: padding margin;
$sides: top left right bottom;
@each $prop in $props {
  @for $i from 0 through 5 {
    .#{$prop}-#{$i} {
      #{$prop}: #{$i}em !important; }
    .#{$prop}--#{$i}-5 {
      #{$prop}: #{$i + .5}em !important; }
    @each $side in $sides {
      .#{$prop}-#{$side}-#{$i} {
        #{$prop}-#{$side}: #{$i}em !important; }
      .#{$prop}-#{$side}--#{$i}-5 {
        #{$prop}-#{$side}: #{$i + .5}em !important; } } } }

// aligns
$aligns: left center right;
@each $align in $aligns {
  .align-#{$align} {
    text-align: #{$align} !important; } }

// widths
$w: 0;
@while $w <= 100 {
	.width-#{$w} {
    width: #{$w}% !important; }
	$w: $w + 10; }

// translates
.x-100 {
  transform: translateX(-100px); }

// fonts
p, input {
  &.light {
    font-weight: 300 !important; }
  &.regular {
    font-weight: 400 !important; }
  &.bold {
    font-weight: 600 !important; } }

// input placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

// bulma extras
.is-marginless {
  @each $side in $sides {
    &.no-margineless-#{$side} {
      margin-#{$side}: -10px !important; } } }
