.itemimgwrap {
  position: relative;
  z-index: 1;

  .itemimg {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden; } }

.itemimg {
  .mosaic-item__content {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f8f6f4;
    overflow: hidden; } }

.itemimg {
  &:hover, &:focus {
    .mosaic-item__content {
      &::before {
        opacity: 0.9;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition-delay: 0s;
        transition-delay: 0s; } } } }

.itemimg {
  .mosaic-item__content {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      content: '';
      background-color: #f8f6f4;
      z-index: 2;
      opacity: 0;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1);
      -webkit-transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s 0.1s cubic-bezier(0.19, 1, 0.22, 1);
      transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.2s 0.1s cubic-bezier(0.19, 1, 0.22, 1); } } }


.itemimg {
  &:hover, &:focus {
    .label {
      opacity: 1;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s; } } }


.itemimg {
  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000;
    font-size: .78571rem;
    line-height: 1.14286rem;
    letter-spacing: .48em;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }


.itemimg {
  &:hover, &:focus {
    .label {
      &::before, &::after {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition-delay: 0.3s;
        transition-delay: 0.3s; } } } }

.itemimg {
  .label {
    font-family: "Raleway-Light";
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000;
    font-size: .78571rem;
    line-height: 1.14286rem;
    letter-spacing: .48em;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94); } }


.itemimg {
  .label {
    span {
      &::before {
        position: absolute;
        top: -31px;
        left: 50%;
        height: 7px;
        width: 7px;
        display: block;
        content: '';
        border: 1px solid #cfbda8;
        border-radius: 100%;
        z-index: 2;
        transform: translate(-3px, 0); } } } }

.itemimg {
  &:hover, &:focus {}
  .label {
    &::before, &::after {
      transform: scale(1, 1);
      transition-delay: 0.3s; } } }

.itemimg {
  &:hover, &:focus {
    .mosaic-item__content {
      &::after {
        border: 12px solid #f8f6f4;
        transition-delay: 0s; } } } }

.itemimg {
  .mosaic-item__content {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      content: '';
      border: 0 solid #f8f6f4;
      z-index: 2;
      transition: border 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94); } } }

.itemimg {
  .label {
    &::before {
      position: absolute;
      top: -130px;
      left: 50%;
      height: 100px;
      width: 1px;
      display: block;
      content: '';
      background-color: #cfbda8;
      z-index: 2;
      transform: scale(1, 0);
      transition: -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); } } }



.itemimg {
  .label {
    &::after {
      position: absolute;
      bottom: -123px;
      left: 50%;
      height: 100px;
      width: 1px;
      display: block;
      content: '';
      background-color: #cfbda8;
      z-index: 2;
      transform: scale(1, 0);
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); } } }

.itemimg {
  img {
    max-height: 99.95%;
    position: relative;
    display: block;
    z-index: 1; } }



@media screen and (max-width: 769px) {
  .itemimg {
    .mosaic-item__content {
      max-height: 250px;
      margin-bottom: 10px; } } }
