.assoc_prev_bottom_slider {
  position: absolute;
  left: 0px;
  top: 47%;
  z-index: 1;
  color: #a7804e;
  cursor: pointer;
  .flaticon-arrows {
    &::before {
      margin-left: 0px; } } }

.assoc_next_bottom_slider {
  position: absolute;
  right: 0px;
  top: 47%;
  z-index: 1;
  color: #a7804e;
  cursor: pointer;
  transform: rotate(180deg);
  .flaticon-arrows {
    &::before {
      margin-left: 0px; } } }




.assoc_mini_slider {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  .slick-prev {
    left: 0px;
    &::before {
      content: "<";
      color: #a7804e; } }
  .slick-next {
    right: 0px;
    &::before {
      content: ">";
      color: #a7804e; } } }

.item_pay_del {
  .bx-soa-pp-company-checkbox {
    display: none; } }

.item_pay_del {
  .bx-soa-pp-company-graf-container {
    &::before {
      content: " ";
      position: absolute;
      top: 7px;
      left: 7px;
      width: 20px;
      height: 20px;
      border: 1px solid #e5e5e5;
      background: #FFF;
      border-radius: 100%;
      z-index: 1; }
    &::after {
      content: " ";
      position: absolute;
      left: 12px;
      top: 13px;
      width: 10px;
      height: 8px;
      background: url(../images/order_ext.png) -64px -9px no-repeat;
      z-index: 2; } } }

.item_pay_del.bx-selected {
  .bx-soa-pp-company-graf-container {
    &::before {
      background-color: #de002b; } } }

.bx-pag-next, .bx-pag-prev {
  position: relative;
  span {
    background: none !important;
    border: none !important; }
  a {
    background: none !important;
    border: none !important;
    span {
      color: #a7804e !important; } } }

.bx-pag-prev {
  width: 145px !important;
  span {
    text-align: left;
    &::after {
      top: 15px;
      position: absolute;
      left: 30px;
      height: 1px;
      width: 90px;
      display: block;
      content: ' ';
      background: #aab1b4; } }
  a {
    span {
      &::after {
        top: 15px;
        position: absolute;
        left: 30px;
        height: 1px;
        width: 90px;
        display: block;
        content: ' ';
        background: #a7804e; } } } }

.bx-pag-next {
  width: 145px !important;
  span {
    text-align: right;
    &::after {
      top: 15px;
      position: absolute;
      right: 30px;
      height: 1px;
      width: 90px;
      display: block;
      content: ' ';
      background: #aab1b4; } }
  a {
    span {
      &::after {
        top: 15px;
        position: absolute;
        right: 30px;
        height: 1px;
        width: 90px;
        display: block;
        content: ' ';
        background: #a7804e; } } } }



.wrap_cat_item_soc {
  .item_socials {
    span {
      &::before {
        font-size: 19px; } } } }

.wrap_list_slider {
  .bx_slide {
    padding-top: 40px;
    padding-bottom: 40px; }
  .slick-prev {
    bottom: -30px;
    left: 40px;
    top: inherit;
    transform: rotate(90deg);
    &::before {
      color: #9b9b9b;
      content: '>'; } }
  .slick-next {
    top: -30px;
    left: 40px;
    transform: rotate(-90deg);
    &::before {
      color: #9b9b9b;
      content: '>'; } } }





.bx_stars_rating_votes {
  display: none !important; }


.bx_item_detail_rating {
  .bx_stars_container {
    width: 89px;
    height: 17px;
    background: white; }
  .bx_stars_bg {
    background: url(../images/bitr_stars.png) no-repeat center !important; } }

.bx_item_detail_rating {
  .bx_stars_progres {
    background: #c69c6d; } }


.title_bottom_cat_item {
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  text-align: center;
  color: #b4b4b4;
  font-size: 16px; }


.second_design {
    background-color: white; }


.item_price {
  .cat_price {
    text-align: right;
    font-size: 34px; } }


.right_elem_content {
  .item_info_section {
    padding-right: 0px !important;
    .cat_elem_prop_wrap {
      float: right; }
    .bx_item_detail_size {
      margin-left: 0px !important;
      padding: 0px !important; }
    .item_buttons {
      float: right; } } }

.count_elem_cat {
  input {
    width: 40px !important; } }


.cat_elem_prop {
  ul {
    .bx_active {
      span {
        color: #a7804e !important; } }
    li {
      &:nth-last-child(2) {
        span {
          &::after {
            content: "" !important; } } }
      float: left;
      width: initial !important;
      span {
        &::after {
          content: "/";
          position: relative;
          padding-left: 3px;
          padding-right: 3px;
          color: black; } } } } }

.wrap_cat_item_soc {
  position: relative;
  float: right;
  width: 100%;
  .bottom_socials {
    min-height: 40px;
    width: 125px;
    .item_socials {
      margin-top: 0px; } } }


.send-message-title {
  position: relative;
  width: 100%;
  padding: 8px;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #b4b4b4; }

.textarea_resize {
  height: 28px; }

.rating-block__name {
  float: right; }

.item_info_section {
  padding: 10px; }

.wrap_detal_text {
    position: relative;
    float: right;
    width: 100%;
    border-top: 1px solid #b4b4b4;
    padding-top: 5px;
    padding-bottom: 12px;
    .send-message {
      font-size: 14px;
      input, textarea {
        resize: none;
        font-size: 14px;
        padding: 5px;
        border: 1px solid #f5ab4c;
        outline: none;
        color: #998b84;
        text-align: center;
        font-family: 'Raleway-Regular';
        margin-top: 3px;
        margin-bottom: 10px;
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid #998b84; } }
    .rating-block {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 14px;
      color: #b4b4b4;
      word-wrap: break-word;
      .rating-block__data {
        margin-left: 10px;
        float: right; }
      .rating-block__text {
        margin-top: 2px; } }
    .text_item {
      margin-top: 20px;
      text-transform: uppercase;
      font-size: 14px;
      color: #b4b4b4;
      word-wrap: break-word; }
    .ladda-button {
      float: right;
      font-size: 14px;
      padding: 10px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      color: white;
      border: none;
      background: #a7804e;
      cursor: pointer;
      &:hover {
       background: #f5ab4c; } } }

.wrap_title_detal_text {
  width: 100%;
  padding-top: 5px;
  span {
    margin-left: 150px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #b4b4b4;
    cursor: pointer;
    &::before {
      position: absolute;
      top: -75%;
      left: -25px;
      height: 20px;
      width: 20px;
      display: block;
      font-size: 35px;
      content: '-';
      color: #b4b4b4;
      transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } } }

.wtdt_close {
  span {
    &::before {
      top: -50%;
      content: '+'; } } }


.iis_close {
  display: none; }



.size_poap {
  width: 100%;
  float: right;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  color: #a7804e;
  cursor: pointer;
  margin-bottom: 10px; }

.wrap_bonys {
  width: 100%;
  float: right;
  text-align: right;
  position: relative;
  margin-bottom: 10px; }

.old_price_item {
  font-size: 18px;
  color: #b4b4b4;
  text-decoration: line-through; }


.old_bonus_item {
  margin-top: 5px;
  span {
    display: inline-block;
    font-size: 18px;
    color: #b4b4b4;
    padding-bottom: 3px;
    border-bottom: 1px solid #b4b4b4; }
  .like_block {
    position: relative;
    width: 219px;
    float: right;
    .left_star {
      float: left;
      margin-top: 5px;
      margin-bottom: 5px;
      .flaticon-star {
        cursor: pointer;
        border: none;
        color: #a7804e;
        &::before {
          font-size: 15px;
          margin-left: 0px; } } }
    .right_heart {
      float: right;
      margin-top: 5px;
      .flaticon-heart {
        cursor: pointer;
        font-size: 12px;
        border: none;
        color: #a7804e; } } } }


.slider_wrap_item {
  width: 68% !important;
  margin-right: 0px !important;
  .bx_slider_conteiner {
    width: 100px !important;
    float: left; }
  .bx_item_slider {
    .bx_bigimages {
      position: relative;
      max-width: 650px;
      overflow: visible;
      background: none;
      border: none;
      margin-top: 120px !important;
      padding-top: 0px;
      float: right;
      height: 600px;
      margin-right: 95px;
      img {
        max-width: 100%;
        right: 0;
        margin: 0 auto;
        position: absolute;
        left: 0;
        bottom: 0; } }
    .bx_slide {
      ul {
        li {
          min-height: 140px;
          width: 100%;
          .cnt {
            border: none; } } } } } }

.bottom_arrow_slider {
  position: relative;
  width: 100%;
  min-height: 90px;
  bottom: 0;
  .bas_left {
    float: left;
    position: relative;
    img {
      filter: grayscale(100%);
      transition: all 0.2s;
      &:hover {
        filter: none; } } }
  .bas_right {
    float: right;
    position: relative;
    img {
      filter: grayscale(100%);
      transition: all 0.2s;
      &:hover {
        filter: none; } } } }


.ffcs_close {
  height: 3px;
  display: inline-block;
  overflow: hidden;
  top: 29px !important;
  &::before {
    font-size: 11px;
    top: -7px;
    position: relative; } }


.items-slider {
  width: 100%;
  height: 337px;
  background-color: #f4f4f4;
  position: relative;
  &:before, &:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 50vw;
    top: 0;
    background: #f4f4f4; }
  &:before {
    left: 0;
    transform: translateX(-100%); }
  &:after {
    right: 0;
    transform: translateX(100%); }
  .wrap_title_prop {
    margin-bottom: 34px;
    span {
      display: inline-block;
      padding: 13px 0;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      color: #b4b4b4;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 105%;
        height: 1px;
        width: calc((100vw - 1280px) / 2 + 720px);
        background-color: #c6c6c6;
        transform: translate(0, -50%);
        transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } } }
  &__list {
    max-width: 950px;
    margin: 0 auto;
    width: 100%;
    .slick-arrow {
      font-size: 0;
      height: 100px;
      width: 100px;
      top: 100px;
      &:before {
        color: #b4b4b4;
        font-size: 100px;
        font-weight: 300;
        text-transform: uppercase;
        transition: color .3s ease; }
      &:hover:before {
        color: #a7804e; } }
    .slick-prev {
      left: -190px;
      &:before {
        content: "<"; } }
    .slick-next {
      right: -190px;
      &:before {
        content: ">"; } }
    &-item {
      height: 100%;
      text-align: center;
      &.slick-slide {
        height: 229px; }
      &.slick-slide img {
        display: inline-block;
        vertical-align: bottom;
        max-height: 100%; }

      &:before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        height: 100%;
        margin-left: -4px; } } } }

.coffee-cup {
  height: 1045px;
  position: relative;
  &__background {
    position: absolute;
    left: 0;
    width: 1556px;
    height: 1011px;
    background: url(../images/cup.png) 50% 50% no-repeat;
    background-size: contain; }
  &__body {
    padding: 128px 0 0;
    width: 356px;
    .wrap_detal_text {
      .wrap_title_detal_text {
        text-align: center;
        span {
          margin-left: 0;
          display: inline-block; } }
      .item_info_section .text_item {
        text-transform: none; } } } }

@media screen and (max-width: 1285px) {

  .slider_wrap_item {
    .bx_item_slider {
      .bx_bigimages {
        max-width: 74%;
        margin-right: 0px; } } }

  .slider_wrap_item {
    width: 55% !important; }

  .bx_item_detail {
    .bx_item_container {
      .bx_rt {
        width: 45%;
        margin-left: 0; } } } }

.wrap_dop_elem_assoc {
  .title_bottom_cat_item {
    text-align: left;
    position: relative;
    &::after {
      position: absolute;
      top: 16px;
      right: 101%;
      height: 1px;
      width: calc((100vw - 1280px) / 2 + 970px);
      display: block;
      content: '';
      background: #cfbda8;
      transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } } }



@media screen and (max-width: 769px) {

  .slider_wrap_item {
    .bx_item_slider {
      .bx_bigimages {
        max-width: 100%;
        margin-right: 0px; } } }

  .slider_wrap_item {
    width: 100% !important; }

  .bx_item_detail {
    .bx_item_container {
      .bx_rt {
        text-align: center;
        width: 100%;
        margin-left: 0;
        margin: 0 auto;
        float: none !important; } } }

  .right_elem_content {
    .wrap_title_prop {
      span {
        float: none !important; } } }

  .item_price {
    .cat_price {
      text-align: center; } }

  .wrap_bonys {
    text-align: center; }

  .old_bonus_item {
    .like_block {
      float: none;
      display: inline-block; } }

  .size_poap {
    float: none;
    text-align: center; }

  .bx_item_detail {
    .bx_item_detail_size {
      width: initial;
      display: inline-block; } }

  .right_elem_content {
    .item_info_section {
      .item_buttons {
        display: inline-block;
        float: none; } } }

  .wrap_cat_item_soc {
    .bottom_socials {
      width: 100%; } }

  .wrap_list_slider {
    width: 100%; }

  .bx_item_detail {
    .bx_item_container {
      .bx_lt {
        float: none; } } }

  .slider_wrap_item {
    .bx_item_slider {
      .bx_slide {
        ul {
          li {} } } } }


  .sliders_lists {
    .slick-cloned {
      min-height: 0px; } }

  .slick-track {
    min-height: 170px;
    overflow-y: hidden; } }
