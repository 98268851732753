.overWrapElementAdaptive {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 20px;
  .wrapElementAdaptive {
    position: relative;
    width: 75px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0);
    padding: 8px;
    &:hover {
      border: 1px solid #e8e7e4; }
    &.activeElemAdaptive {
      border: 1px solid #e8e7e4; }
    img {
      max-width: 100%; }
    .elemAdaptivename {
      text-align: center;
      color: #b4b4b4; } } }


@media screen and (max-width: 1400px) and (max-height: 800px) {
  .mainWrapItemMilagro {
    zoom: 80%;
    margin-top: 20px;
    .dublWrapImgFlay {
      .wrapImgFlay {
        zoom: 40%; } } } }

@media screen and (max-width: 769px) {
  .mainWrapItemMilagro {
    zoom: 100% !important;
    .dublWrapImgFlay {
      .wrapImgFlay {
        zoom: 100% !important; } } } }


@media screen and (max-width: 1100px) and (min-height: 800px) {
  .milagroItemInfoBlock {
    padding-left: 80px;
    .milagroNameInfo {
      font-size: 45px; } } }
