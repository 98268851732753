.settings_submit {
  outline: none;
  background-color: #a7804e;
  border: none;
  color: white;
  letter-spacing: 0.1em;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 7px 5px 7px;
  cursor: pointer; }

.input_settings {
  display: inline-block;
  padding: 5px;
  outline: none;
  border: 1px solid #d7ab7a;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%; }

.wrap_settings {
  position: relative;
  width: 100%;
  max-width: 605px;
  margin-top: 20px;
  text-align: center; }


.wrap_coupon_item {
  width: 100%;
  max-width: 790px;
  position: relative;
  margin-top: 25px; }

.title_coupon_section {
  width: 100%;
  text-align: left;
  color: black;
  text-transform: uppercase;
  margin-top: 40px;
  font-size: 14px; }

.wrap_elem_sale {
  position: relative;
  width: 100%;
  .add_sale_form {
    width: 100%;
    max-width: 790px;
    position: relative;
    margin-top: 10px;
    .adf_input {
      display: inline-block;
      padding: 5px;
      outline: none;
      border: 1px solid #d7ab7a;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 80%;
      margin-right: 1%; }
    .adf_submit {
      cursor: pointer;
      width: 15%;
      outline: none;
      background-color: #a7804e;
      border: none;
      color: white;
      letter-spacing: 0.1em;
      font-size: 14px;
      text-transform: uppercase;
      padding: 6px 0px 6px 0px; } } }


.active_user_page {
  span {
    &::before {
      color: #a7804e !important; } } }

.wrap_big_elem {
  width: 100%;
  position: relative;
  text-align: center;
  .column {
    &:nth-child(2) {
      .item_elem_menu_big {
        border-left-color: rgba(255, 255, 255, 0) !important;
        border-right-color: rgba(255, 255, 255, 0) !important;
        border-bottom-color: rgba(255, 255, 255, 0) !important; } }
    &:nth-child(5) {
      .item_elem_menu_big {
        border-left-color: rgba(255, 255, 255, 0) !important;
        border-right-color: rgba(255, 255, 255, 0) !important; } }
    &:nth-child(1) {
      .item_elem_menu_big {
        border-bottom-color: rgba(255, 255, 255, 0) !important; } }
    &:nth-child(3) {
      .item_elem_menu_big {
        border-bottom-color: rgba(255, 255, 255, 0) !important; } } }
  .item_elem_menu_big {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: black;
    min-height: 30px;
    padding-top: 100px;
    padding-bottom: 100px;
    border: 1px solid #d3d3d3;
    &:hover {
      span {
        &::before {
          color: #a7804e; } } }
    span {
      &::before {
        margin: 0;
        font-size: 50px;
        color: #d3d3d3; } } }
  .elem_caption {
    margin-top: 20px; } }




.wrap_elem_menu_mini {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 150px;
  background: white;
  box-shadow: 0px 1px 25px 0px rgba(50, 50, 50, 0.25);
  .item_elem_menu_mini {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;
    color: black;
    min-height: 30px;
    span {
      &::before {
        margin: 0;
        font-size: 30px; } }
    .hide_elem_caption {
      color: #a7804e;
      padding: 9px;
      background-color: white;
      box-shadow: 1px 4px 15px 0px rgba(50, 50, 50, 0.25);
      position: absolute;
      left: 110%;
      top: 10px;
      transition: all 0.2s;
      opacity: 0;
      font-size: 14px; }
    &:hover {
      color: black;
      .hide_elem_caption {
        opacity: 1; }
      span {
        &::before {
          color: #a7804e; } } } } }




.reg_wca_wrap {
  max-width: 700px !important;
  .input_autor {
    width: 100% !important; } }



.wrap_container_autor {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  .isa_reg {
    margin-top: 20px !important; }
  .main_title_aut {
    font-size: 15px;
    text-transform: uppercase;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px; }
  .title_auth_2 {
    font-size: 13px;
    text-transform: uppercase; }
  .link_registration_user {
    cursor: pointer;
    margin-top: 16px;
    display: inline-block;
    outline: none;
    background-color: #a7804e;
    border: none;
    color: white;
    letter-spacing: 0.1em;
    font-size: 14px;
    text-transform: uppercase;
    padding: 8px 35px 8px 35px;
    &:hover {
      background: #f5ab4c; } }
  .text_left_autor {
    color: #949494;
    margin-top: 10px;
    text-align: left;
    width: 70%;
    display: inline-block;
    font-size: 12px;
    line-height: 1.4; }
  .col_right {
    text-align: center;
    table {
      td {
        text-align: center; } } }
  .input_autor {
    display: inline-block;
    padding: 8px;
    outline: none;
    border: 1px solid #d7ab7a;
    font-size: 11px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 70%; }
  .input_submit_autoriz {
    cursor: pointer;
    margin-top: 50px;
    display: inline-block;
    outline: none;
    background-color: #a7804e;
    border: none;
    color: white;
    letter-spacing: 0.1em;
    font-size: 14px;
    text-transform: uppercase;
    padding: 7px 35px 7px 35px;
    &:hover {
      background: #f5ab4c; } } }


.like_product {
  z-index: 100;
  position: relative;
  cursor: pointer;
  display: inline-block;
  span {
    border-bottom: none !important; }
  .flaticon-heart {
    &::before {
      margin: 0;
      color: #a7804e; } }
  .flaticon-valentines-heart {
    &::before {
      margin: 0;
      color: #a7804e; } } }

.triger_like_1 {
  .flaticon-heart {
    display: none; } }

.triger_like_0 {
  .flaticon-valentines-heart {
    display: none; } }


.edit_title_user {
  color: #a7804e !important;
  text-align: left !important; }

.sale-order-list-container {
  padding-top: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid #d3d3d3; }



.text_wrap_order {
  text-align: center; }

.paid_info {
  text-align: center; }

.wrap_elem_shub_mini {
  text-align: center;
  .elem_shub_mini {
    position: relative;
    display: inline-block;
    width: 27px;
    height: 27px;
    span {
      &::before {
        margin-left: 0px;
        font-size: 30px; } }
    &:hover {
      z-index: 3;
      .elem_mini_shum_hide_elem {
        display: block; } }
    .elem_mini_shum_hide_elem {
      z-index: 5;
      position: absolute;
      width: 216px;
      height: 382px;
      left: -340%;
      background: white;
      top: 26px;
      display: none;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.2s;
      img {
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        left: 0;
        top: 0; }
      .wrap_info_user_history {
        width: 100%;
        position: relative;
        top: 40%;
        padding: 10px;
        opacity: 1;
        .cat_price {
          margin-top: 5px;
          font-size: 18px; }
        .cat_title {
          padding-top: 5px;
          font-size: 14px; }
        .wrap_prop_user {
          width: 100%;
          padding-top: 2px;
          .wpui_color {
            float: right !important; }
          .wrap_prop_user_item {
            padding-left: 2px;
            padding-right: 2px;
            float: left;
            .wrap_prop_user_item_title {
              display: inline-block;
              text-align: center;
              color: #9e9e9e;
              font-size: 11px;
              line-height: 11px;
              width: 100%; }
            .wrap_prop_user_item_znach {
              padding-top: 3px;
              display: inline-block;
              text-align: left;
              color: #a7804e;
              font-size: 14px; } } } } } } }
