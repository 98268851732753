.cart_line_slider {
  position: relative;
  width: 340px;
  a {
    text-align: center;
    img {
      display: inline-block; } } }

.wrap_item_bottom_cart {
  padding-top: 10px; }

.item_bottom_cart {
  float: left;
  width: 50%;
  text-align: center; }

.big_summ_basket {
  text-align: center !important;
  font-size: 30px !important;
  color: #a7804e;
  margin-bottom: 10px;
  display: inline-block;
  margin-bottom: 20px;
  width: 100%; }

.cart-popup {
  position: fixed;
  height: 100%;
  width: 400px;
  top: 0;
  right: 0;
  background-color: $lightGray;
  box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.29);
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transform: translateX(100%);
  transition: all .3s ease;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0); }
  &,
  .bx_ordercart {
    .bx_ordercart_photo_container {
      min-width: 100%;
      border: 1px solid transparent; }
    .bx_ordercart_order_table_container {
      border: 0px;
      border-radius: 0px; }
    .bx_sort_container {
      margin-bottom: 0;
      min-height: 48px;
      font-size: 16px;
      line-height: 48px; }
    .cart-icon {
      position: absolute;
      top: 10px;
      right: 10px; }
    .cart-block {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      &.height-100 {
        height: 100vh; }
      &.product {
        margin-top: 20px;
        margin-bottom: 30px;
        position: relative;
        -webkit-flex-direction: row;
        flex-direction: row;
        img {
          width: 50%; }
        .product-delete {
          position: absolute;
          top: 10px;
          right: 10px; } } }
    .cart-text {
      color: $gray;
      font-size: 14px; }
    .cart-input {
      width: 100%;
      display: block;
      border: 0;
      border-bottom: 1px solid $gold;
      color: $gold;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      @include placeholder {
        color: $gold; }
      & + .cart-button {
        display: block;
        border-top: 0;
        text-align: center;
        margin-top: 0;
        &.empty {
          color: $gray;
          border: 1px solid transparent;
          text-transform: none;
          &:hover {
            background-color: transparent; } } } }
    .cart-button {
      color: $gold;
      border: 1px solid $gold;
      text-transform: uppercase;
      font-size: 15px;
      margin-top: 1em;
      padding: .5em 1em;
      letter-spacing: 0.15em;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: all .15s ease;
      &:hover,
      &.full {
        background-color: $gold;
        color: #fff; }
      &.full {
          width: 100%;
          margin: 0;
          text-align: center; } }
    .cart-icon {
      .backet_count {
        top: -5px; } } } }
