a {
  color: $gold; }
.main-content {
  position: relative;
  transition: .3s all ease;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    transition: .3s all ease; }
  &.faded {
    &::after {
      opacity: 1;
      visibility: visible; } } }


@media screen and (max-width: 1500px) {
  .firstElementMain {
    max-height: 350px;
    .text_name_block {
      blockquote {
        margin-top: 22%; } }
    .itemimg {
      img {
        max-height: inherit;
        top: -40%; } } } }

@media screen and (max-width: 768px) {
    .firstElementMain {
      max-height: inherit;
      .text_name_block {
        blockquote {
          margin-top: 0; } }
      .itemimg {
        img {
          max-height: 99.95%; } } } }
