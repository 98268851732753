.wrapMilagroItemInfoBlock {
  position: relative;
  width: 100%;
  background-color: #f7f6f4; }

.milagroItemInfoBlock {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 120px;
  padding-bottom: 65px;
  padding-top: 26px; }

.wrapMilagroDopTextInfoBlock {
  position: relative;
  height: 210px;
  overflow: hidden; }

.mainWrapItemMilagro {
  position: relative;
  width: 100%;
  margin-top: 60px; }

.itemBigFlag {
  display: inline-block;
  max-width: 60px;
  margin-bottom: 5px; }

.milagroNameInfo {
  font-size: 52px;
  color: #2f2f2f;
  font-family: "Georgia";
  letter-spacing: 10px;
  text-transform: uppercase;
  position: relative;
  max-width: 450px;
  &::before {
    content: ' ';
    position: absolute;
    width: 57px;
    height: 1px;
    background-color: #c4bdb8;
    top: 25px;
    left: -80px; } }

.milagroTextInfo {
  position: relative;
  font-size: 14px;
  font-family: "Raleway-Regular";
  color: #b4b4b4;
  line-height: 1.8;
  margin-top: 40px;
  max-width: 377px; }

.milagroMoreInfo {
  position: relative;
  margin-top: 10px;
  font-family: "Raleway-Bold";
  outline: none;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 11px;
  color: #0b0705;
  cursor: pointer;
  line-height: 1;
  .flaticon-plus {
    display: inline-block;
    margin-right: 5px;
    display: inline-block;
    width: 9px;
    height: 9px;
    text-align: center;
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: relative;
    &::before {
      margin-left: 0 !important;
      font-size: 8px !important;
      display: inline-block;
      width: 9px;
      height: 9px;
      text-align: center;
      position: relative;
      top: -1.6px; } }
  &:hover {
    .flaticon-plus {
      transform: rotate(90deg); } } }

.milagroWeightInfo {
  position: relative;
  margin-top: 45px;
  color: #b4b4b4;
  font-size: 15px;
  letter-spacing: 2px; }

.milagroSocialInfo {
  position: relative;
  margin-top: 20px;
  .titleMilSoc {
    display: inline-block;
    margin-right: 8px;
    color: #989898;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 11px; }
  .elemMilSoc {
    position: relative;
    display: inline-block;
    color: #b0a4a0;
    span {
      &::before {
        font-size: 12px; } }
    .flaticon-vk-social-logotype {
      &::before {
        font-size: 14px; } }
    &:hover {
      color: #a68676; } } }


.wrapSliderMilagroItem {
  position: relative;
  width: 100%; }

.mainWrapItemMilagroTOP {
  .columns {
    margin-left: 0 !important; }
  &::after {
    content: ' ';
    background-color: #f7f6f4;
    position: absolute;
    width: 2000px;
    height: 100%;
    right: -2000px;
    top: 0; }
  &::before {
    content: ' ';
    background-color: #f7f6f4;
    position: absolute;
    width: 2000px;
    height: 100%;
    left: -2000px;
    top: 0; } }

.mainWrapItemMilagroBOTTOM {
  &::after {
    content: ' ';
    background-color: #f7f6f4;
    position: absolute;
    width: 2000px;
    height: 100%;
    right: -2000px;
    top: 0; }
  &::before {
    content: ' ';
    background-color: #f7f6f4;
    position: absolute;
    width: 2000px;
    height: 100%;
    left: -2000px;
    top: 0; } }

.dopTextInfo {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: all 1.2s; }

.milagroDopTextInfoBlock {
  padding-left: 18px;
  padding-top: 10px; }

.wrapArrowSliderMilagroItem {
  position: absolute;
  width: 130px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 45px;
  right: -75px;
  z-index: 2;
  .wrapMilagroSliderArrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: relative;
    span {
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      display: inline-flex;
      position: relative;
      &::before {
        margin-left: 0 !important;
        font-size: 21px; }
      &::after {
        content: ' ';
        width: 25px;
        height: 2px;
        background-color: black;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); } } } }


.wrapPrewMilItemArrow {
  span {
    &::after {
      left: -14px;
      position: absolute;
      top: 9px; } }
  &:hover {
    span {
      transform: translateX(-10px);
      &::after {
        opacity: 1; } } } }

.wrapNextMilItemArrow {
  span {
    transform: rotate(180deg);
    &::after {
      right: -10px;
      position: absolute;
      top: 9px; } }
  &:hover {
    span {
      transform: rotate(180deg) translateX(-10px);
      &::after {
        opacity: 1; } } } }



.wrapMiddleMilagroSlider {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  position: relative; }


.titleMiddleMilagroSlider {
  position: relative;
  width: 100%;
  color: #dbcab2;
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
  &::before {
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #dbcab1;
    top: 5px;
    left: -25px; }
  &::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 2000px;
    left: -2024px;
    top: 8px;
    background-color: #dbcab1; } }

.bottomNameElemMiddleSliderMilagro {
  position: relative;
  font-size: 12px;
  color: #6d6d6d;
  letter-spacing: 3px;
  padding-top: 70px;
  text-align: center;
  .bottomNameElemMiddleSliderMilagroText {
    position: relative;
    display: inline-block;
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: translateY(-10px); }
  &::before {
    content: ' ';
    height: 57px;
    width: 1px;
    display: inline-block;
    background-color: #dbcab1;
    position: relative;
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94),transform 0.4s 0.1s cubic-bezier(0.19, 1, 0.22, 1); } }


.sliderMiddleMilagro {
  position: relative;
  width: 100%; }

.wrapLinkMiddleSliderMilagro {
  outline: none; }


.subMaingrid {
  margin: 0!important; }

.elemMiddleSliderMilagro {
  position: relative;
  width: 372px;
  padding: 10px;
  text-align: center;
  &:hover {
    .bottomNameElemMiddleSliderMilagro {
      .bottomNameElemMiddleSliderMilagroText {
        opacity: 1;
        transition-delay: 0.1s;
        transform: translateY(0); }
      &::before {
        opacity: 1;
        transition-delay: 0s;
        transform: translateY(0); } } }
  .wrapImgElemMiddleSliderMilagro {
    display: flex;
    position: relative;
    height: 300px;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    margin-top: 45px;
    padding-bottom: 35px;
    img {
      display: inline-block;
      max-width: 270px;
      max-height: 300px; } } }

.wrapBottomMilagroOtherItemBrend {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 55px;
  padding-bottom: 55px;
  background-color: #f7f6f4; }

.wrapRightBottomMilagroOtherItemBrend {
  position: relative; }

.wrapLeftBottomMilagroOtherItemBrend {
  position: relative;
  text-align: center; }

.imgOtherBrend {
  max-width: 220px;
  max-height: 290px;
  position: relative;
  display: inline-block;
  z-index: 5;
  margin-left: 250px;
  margin-top: 20px; }

.titleRightBottomMilagroOtherItemBrend {
  width: 100%;
  position: relative;
  text-align: left;
  color: #c0bcb2;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 30px;
  &::before {
    content: ' ';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #dbcab1;
    top: 3px;
    left: -15px; }
  &::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 350px;
    left: -364px;
    top: 5.5px;
    background-color: #dbcab1; } }

.mainTitleRightBottomMilagroOtherItemBrend {
  margin-top: 55px;
  width: 100%;
  font-size: 53px;
  letter-spacing: 8px;
  color: #2f2f2f;
  font-family: "Georgia";
  text-transform: uppercase; }


.linkRightBottomMilagroOtherItemBrend {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-top: 116px;
    font-family: "Raleway-Bold";
    outline: none;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 11px;
    color: #0b0705;
    cursor: pointer;
    &::after {
      content: ' ';
      width: 165px;
      height: 3px;
      position: absolute;
      bottom: -25px;
      left: 0;
      background-color: black;
      transition: all 0.3s; }
    &::before {
      content: ' ';
      width: 53px;
      height: 1px;
      position: absolute;
      top: 4px;
      left: 165px;
      background-color: #e3dad5;
      transition: all 0.3s; }
    &:hover {
      &::after {
        width: 115px; }
      &::before {
        left: 0; } } }
#modalElement {
  .modal-card {
    width: inherit; }
  .modal-card-body {
    background-color: #f8f6f4; } }


.wrapModalElem {
  width: 100%;
  padding: 50px;
  position: relative;
  background-color: #f8f6f4;
  text-align: center; }

.listModalElement {
  list-style: none;
  color: #b4b4b4;
  font-size: 14px;
  line-height: 1.8;
  display: inline-block;
  text-align: left;
  li {
    list-style: disc; } }


.modalLitelBrend {
  position: relative;
  font-size: 12px;
  text-align: left;
  color: #a68676; }

.titelModalInfo {
  font-size: 45px;
  color: #2f2f2f;
  font-family: "Georgia";
  letter-spacing: 8px;
  text-transform: uppercase;
  position: relative;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 45px; }

.closeModalElem {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.8;
  &:hover {
    color: #a68676; } }


.wrapItemTable {
  width: 100%;
  position: relative;
  margin-top: 15px;
  .itemTable {
    max-width: 320px;
    position: relative;
    .itemRowTable {
      width: 100%;
      position: relative;
      display: flex;
      border-top: 1px solid #c4bdb8;
      &:last-child {
        border-bottom: 1px solid #c4bdb8; }
      .leftCol {
        padding: 5px;
        display: inline-flex;
        width: 50%;
        position: relative;
        font-size: 12px;
        color: #b4b4b4;
        border-right: 1px solid #c4bdb8; }
      .rightCol {
        padding: 5px;
        display: inline-flex;
        width: 50%;
        position: relative;
        font-size: 14px;
        color: #b4b4b4;
        letter-spacing: 1px; } } } }

.wrapSliderMilagroItemLeft {
  align-items: flex-start;
  justify-content: center;
  display: flex; }


.wrapItemSlider {
  position: relative !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  img {
    display: inline-flex !important;
    max-height: 600px;
    max-width: 98%; } }

@media screen and (max-width: 768px) {
  .imgOtherBrend {
    margin: 0; }
  .wrapItemTable {
    .itemTable {
      max-width: 290px; } }
  .linkRightBottomMilagroOtherItemBrend {
    margin-top: 56px;
    text-align: center;
    &::before {
      content: none !important; }
    &::after {
      width: 100%; } }
  .mainTitleRightBottomMilagroOtherItemBrend {
    font-size: 18px;
    text-align: center; }
  .titleRightBottomMilagroOtherItemBrend {
    text-align: center; }
  .bottomNameElemMiddleSliderMilagro {
    .bottomNameElemMiddleSliderMilagroText {
      opacity: 1;
      transition-delay: 0.1s;
      transform: translateY(0); }
    &::before {
      opacity: 1;
      transition-delay: 0s;
      transform: translateY(0); } }
  .milagroNameInfo {
    font-size: 42px;
    max-width: 380px; }
  .milagroItemInfoBlock {
    padding-left: 10px; }
  .wrapItemTable {
    margin-top: 50px; }
  .titleMiddleMilagroSlider {
    text-align: center; } }
