.bx_catalog_item_controls {
  position: relative;
  text-align: center;
  margin-top: 10px; }

.weightBlock {
  position: relative;
  width: 100%;
  text-align: left;
  .weightBlockTitle {
    position: relative;
    width: 100%; }
  .weightBlockCount {
    position: relative;
    margin-top: 5px;
    color: #a7804e;
    width: 100%; } }

.listItemWrapImg {
  position: relative;
  min-height: 360px;
  width: 100%;
  .backImgList3d {
    position: absolute;
    bottom: 0 !important;
    top: inherit;
    left: 50%;
    transform: translateX(-50%); }
  .spritespinList {
    position: absolute  !important;
    bottom: 0 !important;
    top: inherit;
    left: 50%;
    transform: translateX(-50%); } }


.titleDesignList {
  position: relative;
  width: 100%;
  margin-top: 10px;
  color: #a7804e;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  &::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 2000px;
    left: -2014px;
    top: 7px;
    background-color: #dbcab1; }
  .imgDesignFlag {
    position: relative;
    display: inline-block;
    max-width: 33px;
    top: 5px;
    margin-right: 10px; } }


.socialBlockWrap {
  position: absolute;
  width: 43px;
  height: 65px;
  overflow: hidden;
  top: 10px;
  left: 5px;
  z-index: 107; }

.eventProductsLabel {
  position: relative;
  width: 100%;
  height: 20px;
  margin-bottom: 1px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 21px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Raleway-Bold"; }

.eventProductsLabelHit {
  color: white;
  background-color: #a6834a; }

.eventProductsLabelSale {
  color: white;
  background-color: #ce0007; }

.eventProductsLabelNew {
  color: #ce0007;
  background-color: white;
  border: 1px solid #ce0007; }

.wrap_go_to_anime_basket {
  position: absolute;
  text-align: center;

  background: white;
  z-index: 199;
  transition: all 400ms ease-out;
  .go_to_anime_basket {
    position: relative;
    top: 0;
    z-index: 109;
    display: inline-block;
    background: white; } }


.src_first {
  display: none; }

.line_beetwen_block {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 1px;
  background: #a7804e;
  opacity: 0; }

.catalog_item_slider {
  .slick-list {
    .slick-track {
      a {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 2px;
        img {
          max-height: 280px;
          display: inline-flex !important;
          max-width: 300px; } } } } }

.catalog_item_slider.slick-slider {
  z-index: 101; }

.wrap_catalog_item_slider {
  opacity: 0;
  position: absolute;
  bottom: 18px;
  width: 100%;
  .slick-slider {
    text-align: center; }
  .slick-slide {
    img {
      display: inline-block; } }
  .assoc_prev_bottom_slider {
    padding: 9px;
    left: -10px;
    top: 43%;
    transition: all 0.3s;
    transform: translateX( -30px ); }
  .assoc_next_bottom_slider {
    padding: 9px;
    right: -10px;
    top: 43%;
    transition: all 0.3s;
    transform: rotate(180deg) translateX( -30px ); } }


.flip10 {
  transform: rotateY( 10deg );
  transition: 50ms ease-out; }

.flip90 {
  transform: rotateY( 90deg );
  transition: 150ms ease-in; }

.flip190 {
  transform: rotateY( 180deg );
  transition: 150ms ease-out; }


.flip180 {
  transform: rotateY( 180deg );
  transition: 150ms ease-out; }



.close_wtci_elem {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 102;
    padding: 8px;
    transition: all 0.2s;
    opacity: 0.6;
    &:hover {
      opacity: 1; }
    .flaticon-close {
      &::before {
        margin-left: 0px;
        font-size: 16px;
        color: #a7804e; } } }


.triger_gall {
 .cat_price, .cat_title, .wrap_bottom_prop {
   transform: rotateY( 180deg ); } }

.triger_gall {
  .wrap_catalog_item_slider {
    opacity: 1;
    z-index: 103; } }


.triger_gall {
  .cat_control_block, .cat_main_img {
    opacity: 0  !important; } }


.bx-active.between_block_f {
  margin-bottom: 25px;
  padding-bottom: 5px !important;
  .line_beetwen_block {
    opacity: 1; } }


.close_wtci {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
  padding: 8px;
  transition: all 0.2s;
  opacity: 0.6;
  &:hover {
    opacity: 1; }
  .flaticon-close {
    &::before {
      margin-left: 0px;
      font-size: 16px;
      color: #a7804e; } } }


.prev_bottom_slider {
  position: absolute;
  top: 10%;
  left: -10%;
  cursor: pointer;
  top: 27%;
  .flaticon-arrows-1 {
    &::before {
      font-size: 60px; } } }

.hidden_filter_count {
  display: none !important; }



.bottom_img_menu {
  position: relative;
  &:hover {
    .bcm_left {
      &::before {
        transform: translate(-100%, -15px); } }
    .bcm_right {
      &::before {
        transform: translate(100%, -15px); } } } }


.bottom_filter_bottoms {
  #set_filter {
    outline: none;
    background-color: #a7804e;
    border: none;
    color: white;
    letter-spacing: 0.1em;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px 0px 5px 0px;
    &:hover {
      background: #f5ab4c; } }
  #modef {
    border-color: #a7804e;
    a {
      color: #a7804e;
      &:hover {
        color: #f5ab4c; } } } }

.bottom_filter_bottoms {
  #set_filter {
    width: 100%;
    cursor: pointer; } }

.bottom_filter_bottoms {
  #del_filter {
    outline: none;
    border: none;
    color: #a7804e;
    cursor: pointer;
    background: white;
    letter-spacing: 0.1em;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 10px;
    border-bottom: 1px solid rgba(252, 252, 252, 0);
    &:hover {
      border-bottom: 1px solid #a7804e; } } }
.wrap_top_cat_img {
  display: block;
  position: relative;
  min-height: 252px;
  background-repeat: no-repeat;
  background-position: center; }


.between_block_f {
  .bx-filter-block {
    width: 70%;
    margin: 0 auto; }
  .p1 {
    left: -30px !important; }
  .p5 {
    right: -25px !important; } }

.wrab_cat_big_im_menu {
  margin-top: 90px; }

.white_botoom_block {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.83);
  transition: all 0.4s;
  opacity: 0;
  &:hover {
    opacity: 1; } }

.bottom_img_menu {
  img {
    width: 100%; } }

.bcm_right {
  left: 90px;
  &::before {
    position: relative;
    margin-top: 22px;
    margin-right: 5px;
    float: left;
    height: 1px;
    width: 100px;
    display: block;
    content: '';
    background: #a7804e;
    transform: translate(0, -15px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } }

.bcm_left {
  right: 90px;
  float: right;
  &::before {
    position: relative;
    margin-top: 22px;
    margin-left: 5px;
    float: right;
    height: 1px;
    width: 100px;
    display: block;
    content: '';
    background: #a7804e;
    transform: translate(0, -15px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } }


.bottom_caption_menu {
  text-decoration: none;
  position: absolute;
  bottom: 70px;
  color: #a7804e;
  font-size: 16px;
  display: inline-block;
  &:hover, &:focus {
    color: #a7804e;
    text-decoration: none; } }

.wrap_button_ajax_add {
  position: relative;
  margin: 0 auto;
  width: 200px; }

.button_ajax_add {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 70px;
  border: 1px solid #a7804e;
  margin-top: 70px;
  margin-bottom: 50px;
  &::before {
    margin-left: 0 !important; } }


.bx_catalog_item {
  position: relative;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1); }

.first_img_hover {
  position: relative; }

.left_cat_elem {
  position: absolute;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.4em;
  transform: rotate(-90deg);
  right: -193px;
  color: #b4b4b4;
  top: 200px;
  &::before {
    position: absolute;
    top: 135%;
    right: calc(101%);
    height: 1px;
    width: 400px;
    display: block;
    content: '';
    background: #b4b4b4;
    transform: translate(0, -15px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } }


.wrap_bottom_prop {
  position: absolute;
  border: 1px solid #d6d6d6;
  border-bottom: 0;
  box-shadow: 0 1px 4px rgba(0,0,0,.1);
  z-index: 99;
  width: 110%;
  top: -2.5%;
  left: -5%;
  right: 0;
  bottom: 0;
  display: none;
  .cat_scu {
    background: white;
    padding-top: 0;
    max-width: 105% !important;
    position: absolute;
    padding-bottom: 20px;
    left: -1px;
    width: calc(100% + 2px);
    text-align: left;
    margin: 0 auto;
    top: 100%;
    border: 1px solid #d6d6d6;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)
    border-top: none;
    display: none;
    padding-left: 30px;
    padding-right: 30px; } }


.hovering_wrap {
  padding: 10px;
  margin: 12px;
  margin-bottom: 15px;
  position: relative;
  transition: all 0.2s;
  .bx_catalog_item_container {
    position: relative; }
  &:hover {
    z-index: 93;
    .wrap_catalog_item_slider {
      opacity: 1;
      z-index: 103;
      .assoc_prev_bottom_slider {
        transform: translateX( 0px ); }
      .assoc_next_bottom_slider {
        transform: rotate(180deg) translateX( 0px ); } }
    .cat_main_img {
      opacity: 0; }
    .wrap_bottom_prop {
      display: block; }
    .cat_scu {
      display: block; }
    //.second_img_hover
    //background-color: white
    //opacity: 1
    //z-index: 100
    .cat_control_block {
      opacity: 1; }
    .cat_title, .cat_price {
      z-index: 100;
      position: relative; } } }



.second_img_hover, .second_design {
  position: absolute;
  z-index: 90;
  padding-top: 151%;
  display: block;
  top: 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0; }

.second_design {
  opacity: 1; }


.cat_main_img {
  z-index: 91;
  display: flex;
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  img {
    max-height: 280px;
    display: inline-flex !important;
    max-width: 300px; } }

.wrap_item_cat_design {
  position: relative;
  &:hover {
    .wrap_botom_prop {
      opacity: 1; } } }

.wrap_botom_prop {
  position: absolute;
  width: 100%;
  top: 40%;
  z-index: 99;
  background: rgba(234, 234, 234, 0.86);
  padding-bottom: 20px;
  opacity: 0;
  transition: all 0.2s;
  .cat_scu {
    padding-top: 0;
    .cat_scu_sub {
      margin-top: 5px; } } }

.razdelitel {
  float: left;
  padding-left: 5px;
  padding-right: 5px; }
.cat_scu {
  width: 85%;
  text-align: left;
  margin: 0 auto;
  .cat_scu_sub {
    max-width: 65%;
    float: right; }
  &:first-child {
    max-width: 50%;
    float: left; }
  text-align: left;
  margin: 0 auto;
  padding-top: 12px;
  //display: none
  .bx_size {
    ul {
      .bx_active {
        color: #a7804e; }
      li {
        &:last-child {
          span {
            &::after {
              content: "" !important; } } }
        float: left;
        width: initial !important;
        span {
          &::after {
            content: "/";
            position: relative;
            padding-left: 3px;
            padding-right: 3px;
            color: black; } } } } }
  .bx_item_detail_size {
    padding-bottom: 10px;
    .bx_item_section_name_gray {
      padding-bottom: 5px;
      display: inline-block; } } }

.cat_scu_sub {
  margin-top: 10px;
  &:first-child {
    float: left; }
  &:last-child {
    float: right; } }


.right_elem_content {
  position: relative;
  max-width: 380px;
  float: right !important;
  .wrap_title_prop {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    span {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-family: "Raleway-Regular";
      position: relative;
      float: right !important;
      &::after {
        position: absolute;
        top: 120%;
        left: calc(101%);
        height: 2px;
        width: calc((100vw - 1280px) / 2 + 720px);
        display: block;
        content: '';
        background: #bfbfbf;
        transform: translate(0, -15px);
        transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); } } } }



.cat_elem_prop_wrap {
  span {
    border: none !important; } }

.basket_elem_button {
  font-size: 15px;
  padding: 10px 15px 10px 15px;
  text-decoration: none;
  color: white;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: #a7804e;
  &:hover, &:focus {
    background: #f5ab4c;
    text-decoration: none;
    color: white; } }

.count_elem_cat {
  float: left;
  input {
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.2) !important;
    font-family: "Raleway-Regular";
    font-style: normal !important;
    float: left;
    color: #b5b5b5  !important;
    outline: none; } }

.wrap_button_count {
  width: 23px;
  float: left;
  .button_count {
    border: 1px solid rgba(128, 128, 128, 0.2) !important;
    background: none !important;
    line-height: 17px !important;
    color: #b5b5b5  !important;
    border-radius: 0 !important; } }

.basket_elem_button_wrap {
  float: left;
  margin-right: 0px !important;
  margin-top: 10px; }




.bx-filter {
  .bx-ui-slider-track-container {
    padding-bottom: 0px; } }

.main-grid {
  margin-top: 50px !important; }

.main-grid-wrap-design {
  max-width: 930px !important; }


.wrap_order {
  .order_right {
    float: right; }
  .order_left {
    float: left; }
  .caption_filter {
    float: left;
    margin-right: 10px; }
  position: relative;
  padding-top: 30px;
  .control {
    float: left;
    .select {
      height: inherit;
      select {
        height: inherit;
        line-height: inherit;
        border: none;
        color: #969696; }
      &::after {
        border-color: #969696; } } } }


.input_filter_line_caption {
  display: block !important; }

.input_filter_line_caption_wrap {
  margin-top: -22px;
  width: 30px !important;


  .max-price, .min-price {
    font-size: 11px !important;
    border: none !important;
    padding: 0px !important;
    height: 18px !important;
    margin-top: -40px !important;
    width: inherit  !important;
    margin-left: -2px !important;
    background: rgba(255, 255, 255, 0) !important; } }

.arrow_in_bottom {
  &::before {
    color: #a7804e;
    font-size: 20px !important;
    margin: 0 !important;
    padding: 0 !important; } }

.container_betwen_line {
  .right, .left {
    text-decoration: none;
    background: none !important; }
  .bx-ui-slider-part {
    background: rgba(255, 255, 255, 0);
    top: 20px;
    span {
      font-size: 13px !important; } }
  .bx-ui-slider-track {
    height: 1px; }
  .bx-ui-slider-pricebar-v, .bx-ui-slider-pricebar-vd, .bx-ui-slider-pricebar-vn {
    background: rgba(255, 255, 255, 0) !important; } }


.wrap_img_cat {
  width: 100%;
  text-align: center;
  padding-bottom: 25px; }

.cat_price {
  margin-top: 20px;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  color: #a7804e;
  font-family: "Raleway-Bold";
  display: none; }

.cat_title {
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
  a {
    width: 70%;
    display: inline-block;
    color: #464646;
    padding-bottom: 14px;
    letter-spacing: 0.1em;
    text-decoration: none;
    border-bottom: 1px solid #b4b4b4; } }

.cat_control_block {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  position: relative;
  z-index: 100;
  .basket_button {
    text-decoration: none;
    color: #a7804e; } }

.wrap_filter_cat_w {
  width: 240px !important; }

.between_block_f {
  .bx-filter-parameters-box-container-block {
    display: none; } }

.item_filter_cat {
  float: left;
  width: 100%;
  display: inline-block;
  padding-top: 25px;
  padding-bottom: 10px;
  border-top: 1px solid #b4b4b4;
  .bx-filter-parameters-box-container {
    padding: 0;
    .checkbox {
      float: left;
      width: 100%;
      .cat_check_wrap {
        margin: 0;
        padding: 0;
        .caption_cat_check {
          position: relative;
          display: inline-block;
          padding: 5px 8px 5px 0px;
          font-size: 16px;
          color: #464646;
          font-family: "Raleway-Regular";
          margin-top: 2px;
          margin-bottom: 2px; }
        .item_cat_chek {
          color: #a7804e;
          &::after {
            position: relative;
            float: right;
            margin-left: 5px;
            height: 1px;
            display: block;
            content: 'x'; } }
        .cat_check {
          display: none; } } } }
  .f_block_title {
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    font-size: 16px !important;
    color: #464646  !important;
    text-decoration: none;
    border: none !important;
    &:hover {
      border: none; }
    i {
      float: right; } } }



.wrap_cat_banner {
  width: 100%;
  .banner_cat {
    width: 100%;
    position: relative;
    img {
      position: relative;
      width: 100%;
      top: 0;
      left: 0; } }
  .wrap_cat_top_caption {
    position: absolute;
    left: 5%;
    top: 35%;
    &::before {
      position: absolute;
      top: 24px;
      right: calc(101%);
      height: 1px;
      width: calc((100vw - 1280px) / 2 + 152px);
      display: block;
      content: '';
      background: #cfbda8;
      transform: translate(0, -15px);
      transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
    .top_caption_cat {
      font-size: 21px;
      width: 100%;
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: 0.3em;
      color: #a7804e; }
    .bottom_caption_cat {
      font-size: 71px;
      margin-top: 10px;
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: 0.1em;
      width: 100%;
      //color: #b4b4b4
      color: white; }
    .bottom_text_small {
        font-size: 18px;
        margin-top: 0;
        display: inline-block;
        letter-spacing: 0.1em;
        color: white;
        font-weight: bold; } } }



.wrap_filter {
  .flaticon-plus {
    position: relative;
    display: inline-block;
    top: -7px;
    color: black !important;
    text-decoration: none;
    &:hover {
      border: none; }
    &::before {
      font-size: 11px; } }

  .fa-angle-up, .fa-angle-down {
    &:before {
      content: none; } }
  .fa-angle-up {
    .flaticon-plus {
      height: 4px;
      display: inline-block;
      overflow: hidden;
      &::before {
        font-size: 11px;
        top: -12.4px;
        position: relative; } } } }

.wrap_filter {
  //position: fixed
  //right: calc((100vw - 1280px) / 2 + 1290px)
  position: absolute;
  right: calc((100vw - 1280px) / 2 + 1000px);
  top: 0;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid #b4b4b4;
  min-width: 270px;
  background-color: white;
  .wrap_filter_ul {
    padding: 0;
    float: left;
    width: 100%;
    background: white !important;
    .main_title_filter {
      border: none !important;
      div {
        font-family: "Raleway-Bold"; } }
    .bx_sitemap_li_title {
      display: inline-block;
      padding-top: 25px;
      padding-bottom: 25px;
      border-top: 1px solid #b4b4b4;
      text-transform: uppercase;
      font-size: 16px;
      width: 100%;
      a {
        color: #464646;
        text-decoration: none; }
      .count_top_filter {
        display: none; } } } }

.wrap_main_cat_block {
  .wrap_filter {
 } }    //display: none


.list_select {
  position: relative;
  min-width: 50px;
  min-height: 20px;
  .list_item {
    min-width: 25px;
    min-height: 20px;
    cursor: pointer;
    float: left; }
  .list_gray {
    img {
      opacity: 0.3;
      filter: grayscale(100%); } } }

.main_title_filter {
  .flaticon-plus {
    position: absolute;
    right: 0px;
    top: 22px;
    cursor: pointer; } }

.item_section_name_gray, .bx_item_section_name_gray {
  display: none; }

.bx_item_detail {
  .bx_item_detail_size {
    ul {
      li {
        width: initial !important;
        .cnt {
          font-size: 16px;
          right: inherit !important;
          left: inherit !important;
          position: relative !important; } } } } }

.bottom_filter_bottoms {
  text-align: center; }

.ffcs_close_wf {
  .smartfilter {
    display: none; } }

.wrapFilterBrend {
  float: left;
  position: relative;
  margin-right: 10px;
  .itemTitle {
    float: left;
    display: inline-block; }
  .linkFilterBrend {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    color: #a2a2a2;
    &:hover {
      color: #a7804e; }
    &.activeBrend {
      color: #a7804e; } } }

.clearfix {
  clear: both; }

.corn1 {
  position: absolute;
  right: -30px;
  top: 30px;
  z-index: 6;
  animation: corn-loop 6s 1s infinite;
  animation-timing-function: ease-out, ease-in-out; }

.corn2 {
  position: absolute;
  left: 0;
  bottom: 35%;
  z-index: 6;
  animation: corn2-loop 5s 1s infinite;
  animation-timing-function: ease-out, ease-in-out; }

.corn3 {
  position: absolute;
  left: 8%;
  bottom: 16%;
  z-index: 6;
  animation: corn1-loop 6s 1s infinite;
  animation-timing-function: ease-out, ease-in-out; }

.dublWrapImgFlay {
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  left: 5%;
  .fly_shadow {
    position: relative;
    z-index: 2;
    animation: shadow-intro 1.25s 0s 1, shadow-loop 4s 1.25s infinite;
    animation-timing-function: ease-out, ease-in-out;
    transform: scale(1);
    padding-top: 10px;
    left: 50px; }
  .wrapImgFlay {
    outline: none;
    user-select: none;
    position: relative;
    overflow: hidden;
    transform: rotate(17deg);
    .imgFlay {
      outline: none;
      user-select: none;
      display: none;
      position: relative;
      animation: bottle-intro 1.25s 0s 1, bottle-loop 4s 1.25s infinite;
      animation-timing-function: ease-out, ease-in-out;
      &.activImgFlay {
        display: inline-block; } } } }

.dublWrap3dblock {
  position: relative;
  outline: none;
  display: none; }

.leftArrow3d {
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  transform: rotate(-135deg) translateZ(0);
  left: 15px;
  z-index: 12;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  display: block;
  height: 25px;
  width: 25px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: 0 0;
  color: transparent;
  top: 50%;
  padding: 10px;
  border: solid  #d4d4d4;
  border-width: 1px 1px 0 0;
  outline: 0;
  opacity: .8;
  &:hover {
    outline: 0;
    background: 0 0;
    color: transparent;
    opacity: 1; } }

.rightArrow3d {
  transform: rotate(45deg) translateZ(0);
  right: 15px;
  z-index: 12;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  display: block;
  height: 25px;
  width: 25px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: 0 0;
  color: transparent;
  top: 50%;
  padding: 10px;
  border: solid  #d4d4d4;
  border-width: 1px 1px 0 0;
  outline: 0;
  opacity: .8;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  &:hover {
    outline: 0;
    background: 0 0;
    color: transparent;
    opacity: 1; } }

.wrap3dblock {
  outline: none;
  user-select: none !important;
  position: relative;
  transform: rotate(17deg);
  .spritespin {
    outline: none;
    user-select: none !important;
    cursor: pointer;
    display: none;
    position: relative;
    animation: bottle-loop 4s 1.25s infinite;
    animation-timing-function: ease-in-out;
    .spritespin-stage {
      outline: none;
      user-select: none !important; }
    .spritespin-canvas {
      outline: none;
      user-select: none !important; }
    &.spriteintro {
      animation: bottle-intro 1.25s 0s 1;
      animation-timing-function: ease-out; } } }

.overWrapImgFlay {
  outline: none;
  position: relative;
  justify-content: center;
  display: flex;
  min-height: 700px;
  .wrapButtonBlock {
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 215px;
    padding: 5px;
    z-index: 3;
    .sideShift {
      width: 100%;
      text-align: center;
      padding-bottom: 5px;
      padding-top: 5px;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.1s;
      img {
        display: inline-block;
        max-width: 100%;
        cursor: pointer; }
      &:hover {
        opacity: 1; } }
    .button360 {
      width: 100%;
      text-align: center;
      padding-bottom: 10px;
      padding-top: 10px;
      opacity: 0.8;
      transition: opacity 0.1s;
      cursor: pointer;
      img {
        display: inline-block; }
      &:hover {
        opacity: 1; } } } }


.wrapBreadcrum {
  width: 100%;
  position: relative;
  padding-top: 10px;
  padding-bottom: 25px;
  .breadcrum {
    display: inline-block;
    font-size: 10px;
    letter-spacing: 1px;
    color: #cdc5c3;
    a {
      display: inline-block;
      text-decoration: none;
      &:hover {
        color: #cdc5c3; } } } }


@keyframes bottle-intro {
  from {
    transform: translateY(-200px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes corn-loop {
  from {
    transform: translateY(0);
    opacity: 1; }
  50% {
    transform: translateY(-5px);
    opacity: 1; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes corn1-loop {
  from {
    transform: translateY(0);
    opacity: 1; }
  50% {
    transform: translate(-7px, 6px);
    opacity: 1; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes corn2-loop {
  from {
    transform: translateY(0);
    opacity: 1; }
  50% {
    transform: translate(6px, 8px);
    opacity: 1; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes bottle-loop {
  from {
    transform: translateY(0);
    opacity: 1; }
  50% {
    transform: translateY(-20px);
    opacity: 1; }
  to {
    transform: translateY(0);
    opacity: 1; } }


@keyframes shadow-intro {
  from {
    transform: scale(2), translateX(20px);
    opacity: 0; }
  to {
    transform: scale(1), translateX(-20px);
    opacity: 1; } }

@keyframes shadow-loop {
  from {
    transform: scale(1), translateX(-60px);
    opacity: 1; }
  50% {
    transform: scale(1.2), translateX(0);
    opacity: .6; }
  to {
    transform: scale(1), translateX(-60px);
    opacity: 1; } }

@media screen and (max-width: 1900px) {
  .wrap_filter {
    width: 13%;
    min-width: 190px !important;
    .bottom_filter_bottoms {
      text-align: center;
      #set_filter, #del_filter {
        font-size: 12px !important; } } } }

@media screen and (max-width: 1818px) {
  .wrap_filter {
    z-index: 50;
    left: 0 !important;
    float: left !important;
    position: relative !important; } }

@media screen and (max-width: 1300px) {
  .footer_block {
    padding-left: 5px;
    padding-right: 5px; } }

@media screen and (max-width: 769px) {
  .main-grid {
    margin-top: 0 !important; }
  .wrap_order {
    padding-top: 15px;
    .order_left {
      width: 100%;
      .wrapFilterBrend {
        width: 100%;
        .itemTitle {
          margin-bottom: 20px; } } } }
  .bottom_text_small {
    span {
      margin: 0 !important;
      width: 100%; } }
  .hovering_wrap {
    z-index: 93;
    .wrap_catalog_item_slider {
      opacity: 1;
      z-index: 103;
      .assoc_prev_bottom_slider {
        transform: translateX( 0px ); }
      .assoc_next_bottom_slider {
        transform: rotate(180deg) translateX( 0px ); } }
    .cat_main_img {
      opacity: 0; }
    .wrap_bottom_prop {
      display: block; }
    .cat_scu {
      display: block; }
    //.second_img_hover
    //background-color: white
    //opacity: 1
    //z-index: 100
    .cat_control_block {
      opacity: 1; }
    .cat_title, .cat_price {
      z-index: 100;
      position: relative; } }

  .titleDesignList {
    text-align: center; }
  .bx_catalog_item {
    display: flex;
    justify-content: center; }
  .hovering_wrap {
    max-width: 310px;
    margin-bottom: 53px;
    width: 100%; }
  .wrap_filter {
    width: 100% !important;
    top: 0px !important;
    text-align: center !important; }
  .clear_mobile {
    clear: both; }

  .wrap_cat_banner {
    .wrap_cat_top_caption {
      top: 38%; } }


  .bottom_caption_menu {
    width: 100%;
    text-align: center;
    float: inherit;
    margin-top: 20px;
    margin-bottom: 20px;
    &::before {
      content: none; } } }

@media screen and (max-width: 650px) {
  .socialBlockWrap {
    z-index: 111; }
  .wrap_order {
    .list_select {
      display: none;
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-top: 40px;
      .list_item {
        float: none;
        display: inline-block; } }
    .order_right {
      position: relative;
      float: none;
      .caption_filter {
        display: none;
        float: none;
        text-align: center;
        position: relative;
        top: -50px; } } }

  .wrapFilterBrend {
    .itemTitle {
      width: 100%;
      text-align: center;
      margin-bottom: 30px; }
    .linkFilterBrend {
      width: 100%;
      text-align: center;
      margin-bottom: 10px; } } }


@media screen and (max-width: 425px) {
  .bottom_caption_cat {
    font-size: 51px; }
  .wrap3dblock {
    left: 8%; }
  .leftArrow3d {
    left: 15%; }
  .wrapImgFlay {
    left: 8%; }
  .overWrapImgFlay {
    min-height: 410px; }
  .corn2 {
    bottom: 23%; }
  .corn3 {
    bottom: 1%; }
  .corn1 {
    width: 65px; } }
