.wrap_dop_elem_assoc {
  position: relative;
  .wrap_botom_prop {
    padding-bottom: 0px; }
  .cat_price {
    font-size: 18px; }
  .cat_title {
    font-size: 14px;
    padding-top: 10px;
    a {
      padding-bottom: 7px; } }
  .cat_scu {
    .bx_item_detail_size {
      margin-bottom: 0px; }
    .bx_size {
      ul {
        .bx_active {
          color: #a7804e  !important;
          border: none  !important;
          background: none  !important;
          &:hover {
            border: none  !important;
            background: none  !important; } } } } } }

.bx-sls {
  .quick-location-tag {
    background-color: #a7804e !important;
    color: white !important; } }

.bx-soa-section-content {
  padding-left: 70px; }

.order_end {
  float: right;
  width: 25%; }
/*  position: fixed
  min-width: 280px
  left: calc((100vw - 1860px) / 2 + 1290px)*/

.bx-soa-section {
  width: 70%; }


.order_coupon {
  .bx-soa-coupon-label {
    float: left;
    label {
      color: #a7804e;
      margin-top: 10px;
      display: inline-block; } }
  .bx-soa-coupon-block {
    float: left; } }

#bx-soa-orderSave {
  display: none; }


.bx-soa-custom-label {
  color: #d7ab7a !important;
  font-family: "Raleway-Regular"; }

.item_pay_del {
  height: 110px;
  .bx-soa-pp-company-image {
    width: 135px;
    height: 64px;
    position: relative !important; } }

.bx-soa-pp-company-smalltitle {
  float: left;
  color: black;
  font-size: 14px;
  margin-left: 25px;
  font-family: "Raleway-Bold";
  top: 30px;
  position: relative; }

.bx-soa-pp-company-graf-container {
  float: left;
  padding-top: 0px !important;
  height: initial !important;
  width: 145px;
  padding-bottom: 8px;
  border: 1px solid #c3cace !important; }



.bx-soa-pp-company-logo {
  .bx-soa-pp-company-graf-container {
    border: 1px solid #c3cace !important; }
  .bx-soa-pp-company-image {
    position: relative;
    width: 135px;
    height: 64px; } }

.bx-soa-pp-company-desc {
  font-size: 12px !important;
  font-family: "Raleway-Bold";
  color: #9a9a9a; }

.bx-soa-pp-list-termin {
  font-size: 14px;
  color: black;
  float: left;
  font-family: "Raleway-Bold"; }

.bx-soa-pp-list-description {
  float: left;
  font-size: 14px;
  margin-left: 10px;
  font-family: "Raleway-Bold"; }


.bx-soa-pp-company-checkbox {
  top: 12px !important;
  left: 12px !important; }

.item_pay_del.bx-selected {
  .bx-soa-pp-company-graf-container {
    border: solid 1px #d7ab7a !important; } }

.item_pay_del {
  .bx-soa-pp-delivery-cost {
    position: relative;
    text-align: center;
    padding-top: 10px;
    width: 135px;
    background: none;
    color: #d7ab7a; } }

.itog_znach {
  .bx-soa-pp-company {
    width: 295px;
    float: right; } }

.alert-warning {
  display: none; }


.btn-order-save {
  color: white;
  border: none;
  font-size: 15px;
  padding: 10px 30px 10px 30px;
  text-decoration: none;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background-color: #a7804e !important;
  margin-top: 15px;
  margin-bottom: 8px;
  display: inline-block; }

.bx-ui-sls-input-block {
  padding: 0 22px 0 30px !important; }

.bx-soa-cart-total-line-total {
  font-family: "Raleway-Bold"; }

.bx-soa-coupon-item {}

.bx-soa-sidebar {
  display: none; }

.bx-soa-order, .bx-soa-section-content {
  .form-control {
    background: none !important;
    border: 1px solid #d7ab7a !important;
    border-radius: 2px;
    box-shadow: none;
    color: #383838;
    height: auto;
    font-size: 15px;
    padding: 8px 13px 7px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px; } }

.bx-soa-editstep {
  border-bottom: none !important;
  font-size: 14px !important; }

.bx-soa-more-btn {
  .btn-md {
    color: white;
    border: none;
    font-size: 15px;
    padding: 10px 30px 10px 30px;
    text-decoration: none;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: #a7804e !important; }
  .pull-left {
    float: left;
    background-color: white !important;
    border: 1px solid #a7804e;
    color: #a7804e; }
  .pull-right {
    float: right; } }

.bx-soa-reference {
  padding-top: 0px !important;
  font-size: 12px !important;
  font-family: "Raleway-Bold"; }


.bx-authform-starrequired {
  padding-left: 5px; }

.bx-soa-section-title {
  font-family: "Raleway-Regular" !important; }

.bx-ui-sls-input-block {
  margin-top: 10px;
  margin-bottom: 10px; }

.bx-soa-section-title-container {
  padding: 29px 19px 29px 79px !important;
  border-bottom: 1px solid #f3f3f3;
  background: #fbfbfb !important;
  cursor: pointer; }

.bx-soa-tooltip-danger {
  .tooltip-inner {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 0;
    padding: 15px 29px;
    background: #fee !important;
    color: #e83333 !important; } }

.text-right {
  float: right; }

.bx-soa-tooltip {
  .tooltip-inner {
    max-width: 100% !important; } }

.bx-soa-section-title {
  position: relative;
  float: left; }

.img_title_order {
  position: absolute;
  left: -40px;
  &::before {
    margin-left: 0px !important;
    color: #e6ab60;
    font-size: 22px !important; } }
