.mainWrapAboutContent {
  padding-left: 105px;
  padding-bottom: 170px; }


.wrapAboutTopElem {
  margin-top: 60px;
  position: relative;
  width: 100%;
  padding-left: 115px;
  padding-top: 42px;
  padding-bottom: 115px;
  padding-right: 25px;
  background-color: #f7f6f4;
  &::after {
    content: ' ';
    background-color: #f7f6f4;
    position: absolute;
    width: 2000px;
    height: 100%;
    right: -2000px;
    top: 0; } }

.titleAboutTopElem {
  width: 100%;
  position: relative;
  color: #a7804e;
  font-size: 45px;
  font-family: "Georgia";
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 3;
  &::before {
    content: ' ';
    position: absolute;
    width: 57px;
    height: 1px;
    background-color: #c4bdb8;
    top: 23px;
    left: -80px; } }

.textAboutTopElem {
  width: 100%;
  position: relative;
  font-family: "Raleway-Regular";
  font-size: 15px;
  margin-top: 27px;
  line-height: 1.6;
  max-width: 890px;
  z-index: 3; }

.textAboutTopElemSub {
  text-transform: none;
  padding-top: 50px;
  padding-left: 30.6%;
  max-width: 830px;
  strong {
    text-transform: uppercase; } }

.textAboutTopElemSubTop {
  max-width: 570px; }


.wrapslider_about {
  width: 100%;
  position: relative;
  .slick-slide {
    img {
      max-height: 420px; } }
  .slider_about {
    width: 100%;
    position: relative;
    z-index: 3;
    .slick-dots {
      top: 93px;
      left: -50px;
      width: 400px;
      height: 100px;
      li {
        width: 25px;
        height: 25px;
        border: 1px solid rgba(219, 202, 177, 0);
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        button {
          width: 7px;
          height: 7px;
          display: inline-block;
          border-radius: 50%;
          border: 1px solid #dbcab1;
          padding: 0;
          &::before {
            content: none; } }
        &:hover {
          button {
            background: black; } } }
      .slick-active {
        border: 1px solid #dbcab1;
        &::before {
          position: absolute;
          content: ' ';
          height: 100px;
          width: 2px;
          background-color: #f7f6f4;
          bottom: 24px; }
        button {
          background: black; } } }
    .slick-list {
      overflow: visible;
      height: 470px; }
    .slick-slider_item {
      width: 100%;
      position: relative;
      padding-top: 218px;
      display: flex !important; } } }

.title-slider_about_left {
  position: relative;
  width: 100%;
  color: #c0bcb2;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  &::before {
    content: ' ';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #dbcab1;
    top: 2px;
    left: -15px; }
  &::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 1350px;
    left: -1364px;
    top: 4.5px;
    background-color: #dbcab1; } }

.text-slider_about_left {
  width: 100%;
  max-width: 320px;
  position: relative;
  color: #b4b4b4;
  margin-top: 50px;
  line-height: 1.4;
  font-size: 18px; }


.slick-slider_about_right {
  position: relative;
  top: -270px;
  padding-left: 0; }

.slick-slider_about_left {
  width: 30%; }


.wrapBottomAbout {
  width: 100%;
  position: relative;
  padding-top: 80px; }


.titleBottomAbout {
  position: relative;
  width: 100%;
  color: #c0bcb2;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  &::before {
    content: ' ';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #dbcab1;
    top: 2px;
    left: 95px; }
  &::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 1350px;
    left: 101px;
    top: 4.5px;
    background-color: #dbcab1; } }

.textBottomAbout {
  width: 100%;
  position: relative;
  font-size: 16px;
  color: #646464;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 3px;
  max-width: 550px;
  margin-top: 50px; }

.textBottomAboutFirst {
  color: #a7804e;
  font-size: 194px;
  font-family: "Georgia";
  width: 136px;
  height: 160px;
  float: left;
  position: relative;
  top: -120px;
  left: -8px; }

.wrapBottomAboutLeft {
  position: relative;
  min-height: 600px; }

.imgBottomAboutLeftTop {
  position: absolute;
  max-width: inherit;
  top: -140px;
  right: -50px;
  z-index: 55; }

.imgBottomAboutLeft {
  position: absolute;
  max-width: inherit;
  right: 12px;
  top: -65px; }

.wrapArrowSliderAbout {
  top: 272px;
  left: -129px; }

.imgTopAboutPlanet {
  position: absolute;
  right: -230px;
  top: 0px;
  z-index: 2; }

.wrapMiddleText {
  position: absolute;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  bottom: 150px;
  align-content: flex-start;
  justify-content: flex-end;
  .middleTextAbout {
    width: 66.66%;
    line-height: 1.6;
    display: inline-block;
    text-align: left;
    font-family: "Raleway-Regular";
    font-size: 15px;
    span {} } }


@media screen and (max-width: 1300px) {
  .mainWrapAboutContent {
    padding-left: 0;
    padding-bottom: 0;
    .wrapAboutTopElem {
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 42px;
      .titleAboutTopElem {
        font-size: 22px;
        &::before {
          content: none; } }
      .textAboutTopElem {
        font-size: 14px;
        .textAboutTopElemSub {
          padding-left: 0;
          padding-top: 20px; } } } } }
@media screen and (max-width: 1000px) {
  .wrapAboutTopElem {
    padding-bottom: 15px;
    margin-top: 0; }
  .wrapMiddleText {
    position: relative;
    margin-top: 30px;
    justify-content: center; }
  .wrapBottomAbout {
    margin: 0 !important;
    padding: 0; }
  .title-slider_about_left {
    text-align: center;
    font-size: 16px; }
  .wrapArrowSliderAbout {
    display: none; }
  .slick-slider_about_right {
    top: inherit !important;
    text-align: center;
    width: 100%  !important;
    img {
      display: inline-block  !important; } }
  .text-slider_about_left {
    max-width: 100%;
    margin-top: 20px; }
  .slick-slider_about_left {
    width: 100%; }
  .wrapslider_about {
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    .slick-active {
      &::before {
        content: none !important; } }
    .slider_about {
      .slick-slider_item {
        padding-top: 0;
        display: block !important; }
      .slick-dots {
        width: 100%;
        height: 50px;
        position: relative;
        top: inherit;
        left: inherit; }
      .slick-list {
        height: inherit;
        .wrapMiddleText {
          .middleTextAbout {
            text-align: left;
            width: 90%;
            max-width: 562px;
            padding: 5px; } } } } } }

@media screen and (max-width: 786px) {
  .wrapMiddleText {
    bottom: 0; }
  .wrapBottomAboutLeft {
    min-height: 420px; }
  .imgBottomAboutLeftTop {
    right: 0; }
  .imgBottomAboutLeft {
    right: 0; } }
